import React from 'react';
import PageHead from '../components/layout/PageHead';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import TaskCalendar from '../components/TaskManager/TaskCalendar';
import TaskList from '../components/TaskManager/TaskList';
import SubjectManager from '../components/TaskManager/SubjectManager';
import WeeklyBoard from '../components/TaskManager/WeeklyBoard';
import TaskStats from '../components/TaskManager/TaskStats';
import { CalendarDays, ListTodo, BookOpen, LayoutGrid } from 'lucide-react';

export default function TaskDashboard() {
  return (
    <>
      <PageHead
        title="Gestion des Tâches et Calendrier Scolaire"
        description="Organisez efficacement vos devoirs, examens et cours avec le calendrier interactif StudyFlow. Planifiez votre emploi du temps et ne manquez plus aucune échéance importante."
        path="/studyflow/taches"
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Gestion des Tâches
          </h1>
          <TaskStats />
        </div>

        <Tabs defaultValue="calendar" className="space-y-6">
          <TabsList className="bg-white p-1 rounded-lg shadow-sm">
            <TabsTrigger value="calendar" className="flex items-center gap-2">
              <CalendarDays className="h-4 w-4" />
              Calendrier
            </TabsTrigger>
            <TabsTrigger value="list" className="flex items-center gap-2">
              <ListTodo className="h-4 w-4" />
              Todo Liste
            </TabsTrigger>
            <TabsTrigger value="board" className="flex items-center gap-2">
              <LayoutGrid className="h-4 w-4" />
              Travail de la semaine
            </TabsTrigger>
            <TabsTrigger value="subjects" className="flex items-center gap-2">
              <BookOpen className="h-4 w-4" />
              Matières
            </TabsTrigger>
          </TabsList>

          <TabsContent value="calendar" className="mt-6">
            <TaskCalendar />
          </TabsContent>

          <TabsContent value="list" className="mt-6">
            <TaskList />
          </TabsContent>

          <TabsContent value="board" className="mt-6">
            <WeeklyBoard />
          </TabsContent>

          <TabsContent value="subjects" className="mt-6">
            <SubjectManager />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}