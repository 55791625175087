import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import NoteEditor from '../components/Notes/NoteEditor';
import NotesList from '../components/Notes/NotesList';
import DocumentImport from '../components/Notes/DocumentImport';
import NotesStats from '../components/Notes/NotesStats';
import { PenTool, FileText, UploadCloud, BarChart2 } from 'lucide-react';

export default function CourseNotesPage() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          Notes de Cours
        </h1>
        <NotesStats />
      </div>

      <Tabs defaultValue="editor" className="space-y-6">
        <TabsList className="bg-white p-1 rounded-lg shadow-sm">
          <TabsTrigger value="editor" className="flex items-center gap-2">
            <PenTool className="h-4 w-4" />
            Éditeur
          </TabsTrigger>
          <TabsTrigger value="notes" className="flex items-center gap-2">
            <FileText className="h-4 w-4" />
            Mes Notes
          </TabsTrigger>
          <TabsTrigger value="import" className="flex items-center gap-2">
            <UploadCloud className="h-4 w-4" />
            Importer
          </TabsTrigger>
          <TabsTrigger value="stats" className="flex items-center gap-2">
            <BarChart2 className="h-4 w-4" />
            Statistiques
          </TabsTrigger>
        </TabsList>

        <TabsContent value="editor">
          <NoteEditor />
        </TabsContent>

        <TabsContent value="notes">
          <NotesList />
        </TabsContent>

        <TabsContent value="import">
          <DocumentImport />
        </TabsContent>

        <TabsContent value="stats">
          <NotesStats detailed />
        </TabsContent>
      </Tabs>
    </div>
  );
}