import React, { useState, useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { useNotesStore } from '../../hooks/useNotesStore';
import { Save, Tag, Clock, AlertTriangle, Bold, Italic, List, ListOrdered, Quote, Undo, Redo } from 'lucide-react';
import { Button } from '../ui/button';

interface NoteEditorProps {
  initialNote?: any;
  onSave?: () => void;
}

export default function NoteEditor({ initialNote, onSave }: NoteEditorProps) {
  const { subjects } = useSubjectStore();
  const { addNote, updateNote } = useNotesStore();
  const [title, setTitle] = useState(initialNote?.title || '');
  const [selectedSubject, setSelectedSubject] = useState(initialNote?.subjectId || '');
  const [tags, setTags] = useState<string[]>(initialNote?.tags || []);
  const [newTag, setNewTag] = useState('');
  const [importance, setImportance] = useState<'low' | 'medium' | 'high'>(initialNote?.importance || 'medium');
  const [urgency, setUrgency] = useState<'low' | 'medium' | 'high'>(initialNote?.urgency || 'medium');
  const [autoSaveStatus, setAutoSaveStatus] = useState<'saved' | 'saving' | 'error'>('saved');

  const editor = useEditor({
    extensions: [StarterKit],
    content: initialNote?.content || '',
    autofocus: true,
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-xl max-w-none focus:outline-none min-h-[500px] p-4',
      },
    },
    onUpdate: ({ editor }) => {
      handleAutoSave(editor.getHTML());
    }
  });

  const handleAutoSave = (content: string) => {
    setAutoSaveStatus('saving');
    try {
      if (initialNote?.id) {
        updateNote(initialNote.id, { content });
      }
      setTimeout(() => setAutoSaveStatus('saved'), 1000);
    } catch (error) {
      setAutoSaveStatus('error');
    }
  };

  const handleSave = () => {
    if (!editor || !title || !selectedSubject) return;

    const noteData = {
      title,
      content: editor.getHTML(),
      subjectId: selectedSubject,
      tags,
      importance,
      urgency
    };

    if (initialNote?.id) {
      updateNote(initialNote.id, noteData);
    } else {
      addNote(noteData);
    }

    if (onSave) {
      onSave();
    }
  };

  const handleAddTag = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newTag.trim()) {
      setTags([...tags, newTag.trim()]);
      setNewTag('');
    }
  };

  const removeTag = (tagToRemove: string) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  if (!editor) return null;

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-6 border-b">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Titre de la note..."
          className="w-full text-2xl font-bold border-none focus:outline-none focus:ring-0"
        />

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Matière
            </label>
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
            >
              <option value="">Sélectionner une matière</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Tags
            </label>
            <div className="flex flex-wrap gap-2">
              {tags.map((tag) => (
                <span
                  key={tag}
                  className="inline-flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-full text-sm"
                >
                  {tag}
                  <button
                    onClick={() => removeTag(tag)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    ×
                  </button>
                </span>
              ))}
              <input
                type="text"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyDown={handleAddTag}
                placeholder="Ajouter un tag..."
                className="flex-1 min-w-[150px] border-none focus:outline-none focus:ring-0"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Importance
            </label>
            <select
              value={importance}
              onChange={(e) => setImportance(e.target.value as 'low' | 'medium' | 'high')}
              className="w-full px-3 py-2 border rounded-md"
            >
              <option value="low">Basse</option>
              <option value="medium">Moyenne</option>
              <option value="high">Haute</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Urgence
            </label>
            <select
              value={urgency}
              onChange={(e) => setUrgency(e.target.value as 'low' | 'medium' | 'high')}
              className="w-full px-3 py-2 border rounded-md"
            >
              <option value="low">Basse</option>
              <option value="medium">Moyenne</option>
              <option value="high">Haute</option>
            </select>
          </div>
        </div>
      </div>

      <div className="border-b p-2 flex items-center gap-2 bg-gray-50">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`p-2 rounded hover:bg-gray-200 ${editor.isActive('bold') ? 'bg-gray-200' : ''}`}
        >
          <Bold className="h-4 w-4" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`p-2 rounded hover:bg-gray-200 ${editor.isActive('italic') ? 'bg-gray-200' : ''}`}
        >
          <Italic className="h-4 w-4" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`p-2 rounded hover:bg-gray-200 ${editor.isActive('bulletList') ? 'bg-gray-200' : ''}`}
        >
          <List className="h-4 w-4" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`p-2 rounded hover:bg-gray-200 ${editor.isActive('orderedList') ? 'bg-gray-200' : ''}`}
        >
          <ListOrdered className="h-4 w-4" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`p-2 rounded hover:bg-gray-200 ${editor.isActive('blockquote') ? 'bg-gray-200' : ''}`}
        >
          <Quote className="h-4 w-4" />
        </button>
        <div className="flex-1" />
        <button
          onClick={() => editor.chain().focus().undo().run()}
          className="p-2 rounded hover:bg-gray-200"
        >
          <Undo className="h-4 w-4" />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          className="p-2 rounded hover:bg-gray-200"
        >
          <Redo className="h-4 w-4" />
        </button>
      </div>

      <div className="border-b">
        <EditorContent editor={editor} />
      </div>

      <div className="p-4 flex items-center justify-between bg-gray-50">
        <div className="flex items-center gap-2 text-sm">
          {autoSaveStatus === 'saving' && (
            <span className="text-gray-500">
              <Clock className="h-4 w-4 inline mr-1" />
              Sauvegarde...
            </span>
          )}
          {autoSaveStatus === 'saved' && (
            <span className="text-green-600">
              <Save className="h-4 w-4 inline mr-1" />
              Sauvegardé
            </span>
          )}
          {autoSaveStatus === 'error' && (
            <span className="text-red-600">
              <AlertTriangle className="h-4 w-4 inline mr-1" />
              Erreur de sauvegarde
            </span>
          )}
        </div>

        <Button
          onClick={handleSave}
          disabled={!title || !selectedSubject || !editor?.getHTML()}
        >
          {initialNote ? 'Mettre à jour' : 'Enregistrer'}
        </Button>
      </div>
    </div>
  );
}