import React, { useState } from 'react';
import { Plus, Edit2, Trash2, ChevronLeft, ChevronRight } from 'lucide-react';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { useFlashcardStore } from '../../hooks/useFlashcardStore';
import FlashcardForm from './FlashcardForm';

export default function FlashcardManager() {
  const { subjects } = useSubjectStore();
  const { flashcards, addFlashcard, updateFlashcard, deleteFlashcard } = useFlashcardStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [studyMode, setStudyMode] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [editingCard, setEditingCard] = useState<any>(null);

  const filteredCards = selectedSubject
    ? flashcards.filter(card => card.subjectId === selectedSubject)
    : flashcards;

  const handleNextCard = () => {
    if (currentCardIndex < filteredCards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setIsFlipped(false);
    }
  };

  const handlePreviousCard = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      setIsFlipped(false);
    }
  };

  const handleEvaluate = (cardId: string, success: boolean) => {
    const card = flashcards.find(c => c.id === cardId);
    if (!card) return;

    const subject = subjects.find(s => s.id === card.subjectId);
    if (!subject) return;

    updateFlashcard(cardId, {
      lastReviewed: new Date().toISOString(),
      success
    });
    
    // Passer à la carte suivante après évaluation
    setTimeout(handleNextCard, 500);
  };

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-4">
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="px-3 py-2 border rounded-md"
            >
              <option value="">Toutes les matières</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            <button
              onClick={() => setStudyMode(!studyMode)}
              className={`px-4 py-2 rounded-md ${
                studyMode
                  ? 'bg-gray-100 text-gray-700'
                  : 'bg-indigo-600 text-white'
              }`}
            >
              {studyMode ? 'Mode édition' : 'Mode révision'}
            </button>
          </div>
          {!studyMode && (
            <button
              onClick={() => {
                setEditingCard(null);
                setIsModalOpen(true);
              }}
              className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4" />
              Nouvelle carte
            </button>
          )}
        </div>

        {studyMode ? (
          <div className="text-center">
            {filteredCards.length > 0 ? (
              <>
                <div
                  className={`relative h-64 cursor-pointer transition-transform duration-700 transform-gpu ${
                    isFlipped ? 'rotate-y-180' : ''
                  }`}
                  onClick={() => setIsFlipped(!isFlipped)}
                  style={{ perspective: '1000px' }}
                >
                  <div className={`absolute inset-0 backface-hidden ${
                    isFlipped ? 'hidden' : 'block'
                  }`}>
                    <div className="h-full flex items-center justify-center p-6 border-2 rounded-lg">
                      <p className="text-lg">{filteredCards[currentCardIndex].question}</p>
                    </div>
                  </div>
                  <div className={`absolute inset-0 backface-hidden ${
                    isFlipped ? 'block' : 'hidden'
                  }`}>
                    {(() => {
                      const subject = subjects.find(s => s.id === filteredCards[currentCardIndex].subjectId);
                      const bgColor = subject?.color || '#4F46E5';
                      const bgColorWithOpacity = `${bgColor}15`;
                      
                      return (
                        <div 
                          className="h-full flex items-center justify-center p-6 border-2 rounded-lg"
                          style={{ backgroundColor: bgColorWithOpacity }}
                        >
                          <p className="text-lg">{filteredCards[currentCardIndex].answer}</p>
                        </div>
                      );
                    })()}
                  </div>
                </div>

                {isFlipped && (
                  <div className="mt-4 flex justify-center gap-4">
                    <button
                      onClick={() => handleEvaluate(filteredCards[currentCardIndex].id, false)}
                      className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition"
                    >
                      À améliorer
                    </button>
                    <button
                      onClick={() => handleEvaluate(filteredCards[currentCardIndex].id, true)}
                      className="px-4 py-2 bg-green-100 text-green-700 rounded-lg hover:bg-green-200 transition"
                    >
                      Apprentissage réussi
                    </button>
                  </div>
                )}

                <div className="mt-6 flex items-center justify-center gap-4">
                  <button
                    onClick={handlePreviousCard}
                    disabled={currentCardIndex === 0}
                    className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
                  >
                    <ChevronLeft className="h-5 w-5" />
                  </button>
                  <span className="text-sm text-gray-500">
                    {currentCardIndex + 1} / {filteredCards.length}
                  </span>
                  <button
                    onClick={handleNextCard}
                    disabled={currentCardIndex === filteredCards.length - 1}
                    className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
                  >
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </div>
              </>
            ) : (
              <p className="text-gray-500">Aucune carte disponible</p>
            )}
          </div>
        ) : (
          <div className="space-y-4">
            {filteredCards.map((card) => {
              const subject = subjects.find(s => s.id === card.subjectId);
              return (
                <div
                  key={card.id}
                  className="p-4 border rounded-lg hover:bg-gray-50"
                  style={{ borderLeft: `4px solid ${subject?.color || '#4F46E5'}` }}
                >
                  <div className="flex justify-between items-start">
                    <div className="space-y-2">
                      <h3 className="font-medium">Question :</h3>
                      <p className="text-gray-600">{card.question}</p>
                      <h3 className="font-medium">Réponse :</h3>
                      <p className="text-gray-600">{card.answer}</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => {
                          setEditingCard(card);
                          setIsModalOpen(true);
                        }}
                        className="p-1 text-gray-400 hover:text-indigo-600 rounded"
                      >
                        <Edit2 className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => deleteFlashcard(card.id)}
                        className="p-1 text-gray-400 hover:text-red-500 rounded"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                  {subject && (
                    <div className="mt-2 flex items-center gap-2 text-sm text-gray-500">
                      <div
                        className="w-3 h-3 rounded-full"
                        style={{ backgroundColor: subject.color }}
                      />
                      <span>{subject.name}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Statistiques</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-2xl font-bold">{flashcards.length}</div>
              <div className="text-sm text-gray-600">Cartes créées</div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-2xl font-bold">
                {flashcards.filter(c => c.lastReviewed).length}
              </div>
              <div className="text-sm text-gray-600">Cartes révisées</div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Progression par matière</h3>
          <div className="space-y-4">
            {subjects.map((subject) => {
              const subjectCards = flashcards.filter(c => c.subjectId === subject.id);
              const reviewedCards = subjectCards.filter(c => c.lastReviewed);
              const progress = subjectCards.length > 0
                ? (reviewedCards.length / subjectCards.length) * 100
                : 0;
              
              return (
                <div key={subject.id}>
                  <div className="flex justify-between mb-1">
                    <span className="text-sm font-medium text-gray-600">
                      {subject.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {reviewedCards.length}/{subjectCards.length}
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="h-2 rounded-full transition-all duration-500"
                      style={{
                        width: `${progress}%`,
                        backgroundColor: subject.color
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <FlashcardForm
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEditingCard(null);
          }}
          onSubmit={(card) => {
            if (editingCard) {
              updateFlashcard(editingCard.id, card);
            } else {
              addFlashcard(card);
            }
            setIsModalOpen(false);
            setEditingCard(null);
          }}
          initialData={editingCard}
        />
      )}
    </div>
  );
}