import React from 'react';
import { Trophy, Target, TrendingUp, Award } from 'lucide-react';
import { useRewardsStore } from '../../hooks/useRewardsStore';
import { useAchievementRules } from '../../hooks/useAchievementRules';
import { useBadgeRules } from '../../hooks/useBadgeRules';

export default function RewardsOverview() {
  const { points, level, nextLevelPoints, streakDays, badges, achievements } = useRewardsStore();
  const { rules: achievementRules } = useAchievementRules();
  const { rules: badgeRules } = useBadgeRules();

  // Calculer le pourcentage de progression vers le niveau suivant
  const progressPercentage = (points / nextLevelPoints) * 100;

  // Calculer les statistiques
  const totalAchievements = achievementRules.length;
  const unlockedAchievements = achievements.length;
  const achievementRate = totalAchievements > 0 
    ? Math.round((unlockedAchievements / totalAchievements) * 100) 
    : 0;

  const totalBadges = badgeRules.length;
  const unlockedBadges = badges.length;
  const badgeRate = totalBadges > 0 
    ? Math.round((unlockedBadges / totalBadges) * 100) 
    : 0;

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      {/* En-tête avec niveau et points */}
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <div className="relative">
            <div className="w-16 h-16 rounded-full bg-indigo-600 flex items-center justify-center text-white text-2xl font-bold">
              {level}
            </div>
            <div className="absolute -top-1 -right-1 bg-yellow-400 text-xs font-medium px-2 py-1 rounded-full">
              Niveau
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-bold">{points} points</h2>
            <p className="text-gray-600">
              {nextLevelPoints - points} points jusqu'au niveau {level + 1}
            </p>
          </div>
        </div>
      </div>

      {/* Barre de progression */}
      <div className="mb-8">
        <div className="h-4 bg-gray-100 rounded-full overflow-hidden">
          <div 
            className="h-full bg-indigo-600 rounded-full transition-all duration-500"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      </div>

      {/* Statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-indigo-50 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-2">
            <Trophy className="h-5 w-5 text-indigo-600" />
            <h3 className="font-medium">Badges</h3>
          </div>
          <p className="text-2xl font-bold">{unlockedBadges}/{totalBadges}</p>
          <p className="text-sm text-gray-600">badges débloqués</p>
        </div>

        <div className="bg-green-50 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-2">
            <Target className="h-5 w-5 text-green-600" />
            <h3 className="font-medium">Réalisations</h3>
          </div>
          <p className="text-2xl font-bold">{achievementRate}%</p>
          <p className="text-sm text-gray-600">taux de réussite</p>
        </div>

        <div className="bg-purple-50 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-2">
            <TrendingUp className="h-5 w-5 text-purple-600" />
            <h3 className="font-medium">Progression</h3>
          </div>
          <p className="text-2xl font-bold">{Math.round(progressPercentage)}%</p>
          <p className="text-sm text-gray-600">vers le niveau suivant</p>
        </div>

        <div className="bg-amber-50 rounded-xl p-6">
          <div className="flex items-center gap-3 mb-2">
            <Award className="h-5 w-5 text-amber-600" />
            <h3 className="font-medium">Série</h3>
          </div>
          <p className="text-2xl font-bold">{streakDays} jours</p>
          <p className="text-sm text-gray-600">de connexion</p>
        </div>
      </div>
    </div>
  );
}