import React from 'react';
import { X, Coffee, Brain } from 'lucide-react';

interface FloatingTimerProps {
  timerData: {
    timeLeft: number;
    isBreak: boolean;
    cycles: number;
  };
  onClose: () => void;
}

export default function FloatingTimer({ timerData, onClose }: FloatingTimerProps) {
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="fixed top-4 right-4 bg-white rounded-lg shadow-lg p-4 z-50">
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          {timerData.isBreak ? (
            <Coffee className="h-5 w-5 text-green-600" />
          ) : (
            <Brain className="h-5 w-5 text-indigo-600" />
          )}
          <div>
            <div className="text-sm text-gray-500">
              {timerData.isBreak ? 'Pause' : 'Session de travail'}
            </div>
            <div className="text-xl font-bold">
              {formatTime(timerData.timeLeft)}
            </div>
          </div>
        </div>
        <div className="text-sm text-gray-500">
          Cycle: {timerData.cycles}
        </div>
        <button
          onClick={onClose}
          className="p-1 hover:bg-gray-100 rounded-full"
        >
          <X className="h-4 w-4 text-gray-500" />
        </button>
      </div>
    </div>
  );
}