import React from 'react';
import { useTaskStore } from '../hooks/useTaskStore';
import { useRevisionStore } from '../hooks/useRevisionStore';
import { useNotesStore } from '../hooks/useNotesStore';
import { useGradeStore } from '../hooks/useGradeStore';
import { useSubjectStore } from '../hooks/useSubjectStore';
import { 
  BarChart2, 
  TrendingUp, 
  Clock, 
  Brain,
  CheckCircle2,
  BookOpen,
  GraduationCap,
  Target
} from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { 
  startOfWeek, 
  endOfWeek, 
  eachDayOfInterval, 
  format, 
  isWithinInterval,
  parseISO,
  isSameDay
} from 'date-fns';
import { fr } from 'date-fns/locale';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function AnalyticsPage() {
  const { tasks } = useTaskStore();
  const { revisionSessions } = useRevisionStore();
  const { notes } = useNotesStore();
  const { grades } = useGradeStore();
  const { subjects } = useSubjectStore();

  // Calcul des statistiques globales
  const completedTasks = tasks.filter(t => t.status === 'done').length;
  const completedRevisions = revisionSessions.filter(s => s.completed).length;
  const averageGrade = grades.length > 0 
    ? grades.reduce((acc, g) => acc + g.value, 0) / grades.length 
    : 0;
  const totalStudyTime = revisionSessions.reduce((acc, s) => acc + s.duration, 0);

  // Calcul des données d'activité hebdomadaire
  const now = new Date();
  const weekStart = startOfWeek(now, { locale: fr });
  const weekEnd = endOfWeek(now, { locale: fr });
  const weekDays = eachDayOfInterval({ start: weekStart, end: weekEnd });

  // Fonction pour calculer les heures pour un jour donné
  const calculateHoursForDay = (day: Date, taskType: 'course' | 'other') => {
    const dayTasks = tasks.filter(task => {
      const taskDate = parseISO(task.date);
      const isTaskType = taskType === 'course' 
        ? task.type === 'course'
        : task.type !== 'course';
      return isSameDay(taskDate, day) && isTaskType;
    });

    return dayTasks.reduce((total, task) => {
      if (!task.time) return total;
      const [hours, minutes] = task.time.split(':').map(Number);
      return total + hours + (minutes / 60);
    }, 0);
  };

  // Données pour le graphique d'activité hebdomadaire
  const weeklyActivityData = {
    labels: weekDays.map(day => format(day, 'EEE', { locale: fr })),
    datasets: [
      {
        label: 'Heures de cours',
        data: weekDays.map(day => calculateHoursForDay(day, 'course')),
        borderColor: '#4F46E5',
        backgroundColor: 'rgba(79, 70, 229, 0.1)',
        fill: true,
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 4,
        pointBackgroundColor: '#4F46E5',
        stack: 'stack1'
      },
      {
        label: 'Tâches personnelles',
        data: weekDays.map(day => calculateHoursForDay(day, 'other')),
        borderColor: '#818CF8',
        backgroundColor: 'rgba(129, 140, 248, 0.1)',
        fill: true,
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 4,
        pointBackgroundColor: '#818CF8',
        stack: 'stack1'
      }
    ]
  };

  // Données pour le graphique de progression des notes
  const gradeProgressData = {
    labels: subjects.map(s => s.name),
    datasets: [{
      label: 'Moyenne par matière',
      data: subjects.map(subject => {
        const subjectGrades = grades.filter(g => g.subjectId === subject.id);
        return subjectGrades.length > 0 
          ? subjectGrades.reduce((acc, g) => acc + g.value, 0) / subjectGrades.length 
          : 0;
      }),
      backgroundColor: subjects.map(s => s.color),
      borderRadius: 8,
      borderSkipped: false,
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: '#1F2937',
        bodyColor: '#1F2937',
        borderColor: '#E5E7EB',
        borderWidth: 1,
        padding: 12,
        boxPadding: 6,
        usePointStyle: true,
        callbacks: {
          label: function(context: any) {
            return `${context.parsed.y.toFixed(1)}h`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          padding: 10,
          font: {
            size: 12
          }
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          padding: 10,
          font: {
            size: 12
          }
        },
        stacked: true
      }
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-4xl font-bold text-gray-900 mb-12">
        Vue d'ensemble
      </h1>

      {/* Statistiques principales */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
        <div className="bg-gradient-to-br from-indigo-500 to-indigo-600 p-6 rounded-2xl text-white">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white/20 rounded-lg">
              <CheckCircle2 className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-medium">Tâches</h3>
          </div>
          <p className="text-3xl font-bold mb-1">{completedTasks}</p>
          <p className="text-sm text-indigo-100">sur {tasks.length} tâches</p>
        </div>

        <div className="bg-gradient-to-br from-purple-500 to-purple-600 p-6 rounded-2xl text-white">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white/20 rounded-lg">
              <Brain className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-medium">Révisions</h3>
          </div>
          <p className="text-3xl font-bold mb-1">{completedRevisions}</p>
          <p className="text-sm text-purple-100">sessions terminées</p>
        </div>

        <div className="bg-gradient-to-br from-blue-500 to-blue-600 p-6 rounded-2xl text-white">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white/20 rounded-lg">
              <GraduationCap className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-medium">Moyenne</h3>
          </div>
          <p className="text-3xl font-bold mb-1">{averageGrade.toFixed(2)}</p>
          <p className="text-sm text-blue-100">sur 20</p>
        </div>

        <div className="bg-gradient-to-br from-indigo-500 to-indigo-600 p-6 rounded-2xl text-white">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white/20 rounded-lg">
              <Clock className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-medium">Temps d'étude</h3>
          </div>
          <p className="text-3xl font-bold mb-1">{Math.round(totalStudyTime / 60)}h</p>
          <p className="text-sm text-indigo-100">cette semaine</p>
        </div>
      </div>

      {/* Graphiques */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
        <div className="bg-white p-8 rounded-2xl shadow-sm">
          <h3 className="text-lg font-medium mb-8 flex items-center gap-2">
            <TrendingUp className="h-5 w-5 text-indigo-500" />
            Progression par matière
          </h3>
          <div className="h-80">
            <Bar 
              data={gradeProgressData}
              options={chartOptions}
            />
          </div>
        </div>

        <div className="bg-white p-8 rounded-2xl shadow-sm">
          <h3 className="text-lg font-medium mb-8 flex items-center gap-2">
            <Target className="h-5 w-5 text-indigo-500" />
            Activité hebdomadaire
          </h3>
          <div className="h-80">
            <Line 
              data={weeklyActivityData}
              options={chartOptions}
            />
          </div>
        </div>
      </div>

      {/* Performance par matière */}
      <div className="bg-white p-8 rounded-2xl shadow-sm">
        <h3 className="text-lg font-medium mb-8 flex items-center gap-2">
          <BookOpen className="h-5 w-5 text-indigo-500" />
          Performance par matière
        </h3>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {subjects.map(subject => {
            const subjectGrades = grades.filter(g => g.subjectId === subject.id);
            const subjectTasks = tasks.filter(t => t.subject === subject.name);
            const subjectNotes = notes.filter(n => n.subjectId === subject.id);
            const average = subjectGrades.length > 0 
              ? (subjectGrades.reduce((acc, g) => acc + g.value, 0) / subjectGrades.length)
              : 0;
            
            return (
              <div 
                key={subject.id}
                className="relative p-6 rounded-2xl overflow-hidden"
                style={{ 
                  backgroundColor: `${subject.color}10`,
                  borderLeft: `4px solid ${subject.color}` 
                }}
              >
                <div className="relative z-10">
                  <h4 className="text-xl font-medium mb-6">{subject.name}</h4>
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Moyenne</span>
                      <span className="text-2xl font-bold" style={{ color: subject.color }}>
                        {average.toFixed(1)}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Tâches</span>
                      <span className="font-medium">
                        {subjectTasks.filter(t => t.status === 'done').length}/{subjectTasks.length}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Notes</span>
                      <span className="font-medium">{subjectNotes.length}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}