import React, { useEffect } from 'react';
import { Play, Pause, RotateCcw, Coffee, Brain } from 'lucide-react';
import { useTimerStore } from '../../hooks/useTimerStore';

interface RevisionTimerProps {
  onTimerStart: () => void;
  onTimerUpdate: (data: { timeLeft: number; isBreak: boolean; cycles: number }) => void;
}

export default function RevisionTimer({ onTimerStart, onTimerUpdate }: RevisionTimerProps) {
  const {
    timeLeft,
    isRunning,
    isBreak,
    cycles,
    lastUpdate,
    setTimeLeft,
    setIsRunning,
    setIsBreak,
    setCycles,
    updateLastUpdate,
    reset
  } = useTimerStore();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isRunning) {
      interval = setInterval(() => {
        const now = Date.now();
        const delta = Math.floor((now - lastUpdate) / 1000);
        
        if (delta >= 1) {
          const newTimeLeft = Math.max(0, timeLeft - delta);
          setTimeLeft(newTimeLeft);
          updateLastUpdate();
          onTimerUpdate({ timeLeft: newTimeLeft, isBreak, cycles });

          if (newTimeLeft === 0) {
            handleTimerComplete();
          }
        }
      }, 100);
    }

    return () => clearInterval(interval);
  }, [isRunning, timeLeft, lastUpdate]);

  const handleTimerComplete = () => {
    if (!isBreak) {
      setCycles(cycles + 1);
      setIsBreak(true);
      setTimeLeft(5 * 60); // 5 minutes de pause
    } else {
      setIsBreak(false);
      setTimeLeft(25 * 60); // 25 minutes de travail
    }
    setIsRunning(false);
    updateLastUpdate();
  };

  const toggleTimer = () => {
    if (!isRunning) {
      onTimerStart();
      updateLastUpdate();
    }
    setIsRunning(!isRunning);
  };

  const resetTimer = () => {
    reset();
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex flex-col items-center">
        <div className="mb-8">
          {isBreak ? (
            <div className="flex items-center gap-2 text-green-600">
              <Coffee className="h-6 w-6" />
              <span className="text-xl font-medium">Pause bien méritée</span>
            </div>
          ) : (
            <div className="flex items-center gap-2 text-indigo-600">
              <Brain className="h-6 w-6" />
              <span className="text-xl font-medium">Session de travail</span>
            </div>
          )}
        </div>

        <div className="text-6xl font-bold mb-8 text-gray-900">
          {formatTime(timeLeft)}
        </div>

        <div className="flex items-center gap-4 mb-8">
          <button
            onClick={toggleTimer}
            className="p-4 rounded-full bg-indigo-600 text-white hover:bg-indigo-700 transition"
          >
            {isRunning ? (
              <Pause className="h-6 w-6" />
            ) : (
              <Play className="h-6 w-6" />
            )}
          </button>

          <button
            onClick={resetTimer}
            className="p-4 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200 transition"
          >
            <RotateCcw className="h-6 w-6" />
          </button>
        </div>

        <div className="grid grid-cols-3 gap-4 w-full max-w-md">
          <div className="text-center p-4 bg-gray-50 rounded-lg">
            <div className="text-2xl font-bold text-gray-900">{cycles}</div>
            <div className="text-sm text-gray-500">Cycles</div>
          </div>

          <div className="text-center p-4 bg-gray-50 rounded-lg">
            <div className="text-2xl font-bold text-gray-900">
              {Math.floor(cycles * 25)} min
            </div>
            <div className="text-sm text-gray-500">Temps total</div>
          </div>

          <div className="text-center p-4 bg-gray-50 rounded-lg">
            <div className="text-2xl font-bold text-gray-900">
              {isBreak ? 'Pause' : 'Travail'}
            </div>
            <div className="text-sm text-gray-500">Mode actuel</div>
          </div>
        </div>
      </div>
    </div>
  );
}