import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface RewardsState {
  points: number;
  level: number;
  nextLevelPoints: number;
  badges: string[];
  achievements: string[];
  streakDays: number;
  lastLoginDate: string | null;
  
  // Actions
  addPoints: (amount: number, userId?: string) => Promise<void>;
  unlockBadge: (badgeId: string, userId?: string) => Promise<void>;
  unlockAchievement: (achievementId: string, userId?: string) => Promise<void>;
  checkLevelUp: () => void;
  updateStreak: () => void;
  reset: () => void;
}

export const useRewardsStore = create<RewardsState>()(
  persist(
    (set, get) => ({
      points: 0,
      level: 1,
      nextLevelPoints: 1000,
      badges: [],
      achievements: [],
      streakDays: 0,
      lastLoginDate: null,

      addPoints: async (amount: number, userId?: string) => {
        set((state) => ({ points: state.points + amount }));
        get().checkLevelUp();

        if (userId) {
          try {
            await updateDoc(doc(db, 'users', userId), {
              'rewards.points': get().points,
              'rewards.level': get().level,
              updatedAt: new Date().toISOString()
            });
          } catch (error) {
            console.error('Error updating points:', error);
          }
        }
      },

      unlockBadge: async (badgeId: string, userId?: string) => {
        if (!get().badges.includes(badgeId)) {
          set((state) => ({
            badges: [...state.badges, badgeId]
          }));

          if (userId) {
            try {
              await updateDoc(doc(db, 'users', userId), {
                'rewards.badges': get().badges,
                updatedAt: new Date().toISOString()
              });
            } catch (error) {
              console.error('Error unlocking badge:', error);
            }
          }
        }
      },

      unlockAchievement: async (achievementId: string, userId?: string) => {
        if (!get().achievements.includes(achievementId)) {
          set((state) => ({
            achievements: [...state.achievements, achievementId]
          }));

          if (userId) {
            try {
              await updateDoc(doc(db, 'users', userId), {
                'rewards.achievements': get().achievements,
                updatedAt: new Date().toISOString()
              });
            } catch (error) {
              console.error('Error unlocking achievement:', error);
            }
          }
        }
      },

      checkLevelUp: () => {
        const { points, level, nextLevelPoints } = get();
        
        if (points >= nextLevelPoints) {
          set((state) => ({
            level: state.level + 1,
            nextLevelPoints: Math.floor(state.nextLevelPoints * 1.5)
          }));
        }
      },

      updateStreak: () => {
        const today = new Date().toISOString().split('T')[0];
        const lastLogin = get().lastLoginDate;

        if (!lastLogin) {
          set({ streakDays: 1, lastLoginDate: today });
          return;
        }

        const lastDate = new Date(lastLogin);
        const currentDate = new Date(today);
        const diffDays = Math.floor((currentDate.getTime() - lastDate.getTime()) / (1000 * 60 * 60 * 24));

        if (diffDays === 1) {
          set((state) => ({
            streakDays: state.streakDays + 1,
            lastLoginDate: today
          }));
        } else if (diffDays > 1) {
          set({
            streakDays: 1,
            lastLoginDate: today
          });
        }
      },

      reset: () => set({
        points: 0,
        level: 1,
        nextLevelPoints: 1000,
        badges: [],
        achievements: [],
        streakDays: 0,
        lastLoginDate: null
      })
    }),
    {
      name: 'rewards-store'
    }
  )
);