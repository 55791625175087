import React, { useState } from 'react';
import { Coffee, Brain, Activity, Timer } from 'lucide-react';
import toast from 'react-hot-toast';
import { useBreakTimer } from '../../hooks/useBreakTimer';

const breaks = [
  {
    id: '1',
    title: 'Pause café',
    duration: '15',
    description: 'Prenez un café et détendez-vous',
    icon: <Coffee className="h-6 w-6" />,
    color: 'bg-amber-500'
  },
  {
    id: '2',
    title: 'Pause méditation',
    duration: '10',
    description: 'Pratiquez une courte méditation',
    icon: <Brain className="h-6 w-6" />,
    color: 'bg-purple-500'
  },
  {
    id: '3',
    title: 'Pause marche',
    duration: '20',
    description: 'Faites quelques pas pour vous dégourdir',
    icon: <Activity className="h-6 w-6" />,
    color: 'bg-green-500'
  }
];

export default function BreakReminder() {
  const [activeBreak, setActiveBreak] = useState<string | null>(null);
  const { startTimer, isActive, timeLeft } = useBreakTimer();

  const handleStartBreak = (breakItem: typeof breaks[0]) => {
    if (isActive) {
      toast.error('Une pause est déjà en cours !');
      return;
    }

    setActiveBreak(breakItem.id);
    startTimer();
    toast.success(`Pause ${breakItem.title} démarrée !`);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <Timer className="h-6 w-6 text-indigo-600" />
          <h2 className="text-xl font-semibold">Rappels de pause</h2>
        </div>
        {isActive && (
          <div className="text-sm text-gray-500">
            Temps restant : {formatTime(timeLeft)}
          </div>
        )}
      </div>

      <div className="grid gap-6 md:grid-cols-3">
        {breaks.map((breakItem) => (
          <div
            key={breakItem.id}
            className={`p-6 rounded-xl transition-all ${
              activeBreak === breakItem.id
                ? 'ring-2 ring-indigo-500 bg-indigo-50'
                : 'hover:bg-gray-50'
            }`}
          >
            <div className="flex items-center gap-4 mb-4">
              <div className={`p-3 rounded-lg ${breakItem.color} text-white`}>
                {breakItem.icon}
              </div>
              <div>
                <h3 className="font-medium text-gray-900">{breakItem.title}</h3>
                <p className="text-sm text-gray-500">{breakItem.duration} minutes</p>
              </div>
            </div>

            <p className="text-sm text-gray-600 mb-4">{breakItem.description}</p>

            <button
              onClick={() => handleStartBreak(breakItem)}
              disabled={isActive}
              className={`w-full py-2 rounded-lg transition-colors ${
                isActive
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-indigo-600 text-white hover:bg-indigo-700'
              }`}
            >
              {activeBreak === breakItem.id ? 'En cours...' : 'Commencer maintenant'}
            </button>
          </div>
        ))}
      </div>

      <div className="mt-8 p-4 bg-indigo-50 rounded-lg">
        <p className="text-sm text-indigo-600">
          💡 Conseil : Faites une pause de 5-15 minutes toutes les heures pour maintenir votre productivité.
        </p>
      </div>
    </div>
  );
}