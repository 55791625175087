import React from 'react';
import { TrendingUp, Award, Target, GraduationCap } from 'lucide-react';
import { useGradeStore } from '../../hooks/useGradeStore';
import { useTaskStore } from '../../hooks/useTaskStore';
import { useRevisionStore } from '../../hooks/useRevisionStore';

export default function MotivationalStats() {
  const { grades } = useGradeStore();
  const { tasks } = useTaskStore();
  const { revisionSessions } = useRevisionStore();

  // Calcul de la moyenne générale
  const averageGrade = grades.length > 0
    ? (grades.reduce((acc, grade) => acc + grade.value, 0) / grades.length).toFixed(1)
    : "N/A";

  // Calcul du taux de complétion des tâches
  const completedTasksRate = tasks.length > 0
    ? Math.round((tasks.filter(t => t.status === 'done').length / tasks.length) * 100)
    : 0;

  // Calcul du taux de réussite des sessions de révision
  const completedSessionsRate = revisionSessions.length > 0
    ? Math.round((revisionSessions.filter(s => s.completed).length / revisionSessions.length) * 100)
    : 0;

  const stats = [
    {
      title: "Moyenne générale",
      value: `${averageGrade}/20`,
      description: "votre moyenne actuelle",
      icon: <GraduationCap className="h-5 w-5" />,
      color: "bg-blue-500"
    },
    {
      title: "Tâches complétées",
      value: `${completedTasksRate}%`,
      description: "de vos objectifs atteints",
      icon: <Target className="h-5 w-5" />,
      color: "bg-green-500"
    },
    {
      title: "Sessions de révision",
      value: `${completedSessionsRate}%`,
      description: "des sessions terminées avec succès",
      icon: <TrendingUp className="h-5 w-5" />,
      color: "bg-purple-500"
    },
    {
      title: "Progression globale",
      value: `${Math.round((completedTasksRate + completedSessionsRate) / 2)}%`,
      description: "d'amélioration continue",
      icon: <Award className="h-5 w-5" />,
      color: "bg-amber-500"
    }
  ];

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <h2 className="text-xl font-semibold mb-6">Vos statistiques</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="p-6 rounded-xl bg-gray-50"
          >
            <div className="flex items-center gap-4 mb-4">
              <div className={`p-3 rounded-lg ${stat.color} text-white`}>
                {stat.icon}
              </div>
              <div>
                <p className="text-2xl font-bold">{stat.value}</p>
                <h3 className="text-sm font-medium text-gray-900">{stat.title}</h3>
              </div>
            </div>
            <p className="text-sm text-gray-600">{stat.description}</p>
          </div>
        ))}
      </div>

      <div className="mt-8 p-4 bg-indigo-50 rounded-lg">
        <p className="text-sm text-indigo-600">
          💪 Continuez sur votre lancée ! Chaque petit progrès compte dans votre réussite.
        </p>
      </div>
    </div>
  );
}