import React from 'react';
import { Target, Clock, CheckCircle2, Trophy } from 'lucide-react';
import { useRewardsStore } from '../../hooks/useRewardsStore';
import { useTaskStore } from '../../hooks/useTaskStore';
import { useRevisionStore } from '../../hooks/useRevisionStore';
import toast from 'react-hot-toast';

interface Challenge {
  id: string;
  title: string;
  description: string;
  points: number;
  progress: number;
  total: number;
  icon: React.ReactNode;
  color: string;
  type: 'tasks' | 'revision' | 'break';
}

export default function DailyChallenges() {
  const { addPoints } = useRewardsStore();
  const { tasks } = useTaskStore();
  const { revisionSessions } = useRevisionStore();
  const [completedChallenges, setCompletedChallenges] = React.useState<string[]>([]);

  // Calcul des progrès réels basés sur les données du store
  const todayTasks = tasks.filter(task => {
    const taskDate = new Date(task.date);
    const today = new Date();
    return taskDate.toDateString() === today.toDateString();
  });

  const todayRevisions = revisionSessions.filter(session => {
    const sessionDate = new Date(session.date);
    const today = new Date();
    return sessionDate.toDateString() === today.toDateString();
  });

  const challenges: Challenge[] = [
    {
      id: 'daily-tasks',
      title: 'Planificateur du jour',
      description: 'Ajoutez 3 tâches à votre calendrier',
      points: 50,
      progress: todayTasks.length,
      total: 3,
      icon: <Clock className="h-6 w-6" />,
      color: 'bg-blue-500',
      type: 'tasks'
    },
    {
      id: 'daily-revision',
      title: 'Session productive',
      description: 'Complétez une session de révision de 30 minutes',
      points: 100,
      progress: todayRevisions.filter(s => s.completed).length,
      total: 1,
      icon: <Target className="h-6 w-6" />,
      color: 'bg-purple-500',
      type: 'revision'
    },
    {
      id: 'daily-break',
      title: 'Objectif bien-être',
      description: 'Faites une pause de 15 minutes',
      points: 30,
      progress: completedChallenges.includes('daily-break') ? 1 : 0,
      total: 1,
      icon: <CheckCircle2 className="h-6 w-6" />,
      color: 'bg-green-500',
      type: 'break'
    }
  ];

  const handleCompleteChallenge = async (challenge: Challenge) => {
    if (completedChallenges.includes(challenge.id)) return;

    // Vérifier si le défi peut être complété
    if (challenge.progress >= challenge.total) {
      try {
        // Ajouter les points
        await addPoints(challenge.points);
        
        // Marquer le défi comme complété
        setCompletedChallenges(prev => [...prev, challenge.id]);

        // Afficher une notification de succès
        toast.success(`Défi complété ! +${challenge.points} points`, {
          icon: '🎉',
          duration: 3000
        });

        // Vérifier si tous les défis sont complétés pour le bonus
        const allChallengesCompleted = challenges.every(c => 
          completedChallenges.includes(c.id) || c.id === challenge.id
        );

        if (allChallengesCompleted) {
          await addPoints(500); // Bonus pour avoir complété tous les défis
          toast.success('Bonus journalier ! +500 points', {
            icon: '🌟',
            duration: 4000
          });
        }
      } catch (error) {
        toast.error('Erreur lors de la complétion du défi');
      }
    } else {
      toast.error('Complétez d\'abord les objectifs du défi !');
    }
  };

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-3">
          <Trophy className="h-6 w-6 text-indigo-600" />
          <h2 className="text-xl font-semibold">Défis du jour</h2>
        </div>
        <div className="text-sm text-gray-500">
          Se réinitialise dans {new Date().getHours() < 12 ? '12' : '24'}h
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-3">
        {challenges.map((challenge) => {
          const isCompleted = completedChallenges.includes(challenge.id);
          const progressPercentage = (challenge.progress / challenge.total) * 100;

          return (
            <div
              key={challenge.id}
              className={`p-6 rounded-xl ${
                isCompleted ? 'bg-green-50' : 'bg-gray-50'
              }`}
            >
              <div className="flex items-center gap-4 mb-4">
                <div className={`p-3 rounded-lg ${challenge.color} text-white`}>
                  {challenge.icon}
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">{challenge.title}</h3>
                  <p className="text-sm text-gray-500">{challenge.points} points</p>
                </div>
              </div>

              <p className="text-sm text-gray-600 mb-4">{challenge.description}</p>

              <div className="space-y-3">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Progression</span>
                  <span className="font-medium">
                    {challenge.progress}/{challenge.total}
                  </span>
                </div>

                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div
                    className={`h-2 rounded-full transition-all duration-500 ${
                      isCompleted ? 'bg-green-500' : challenge.color
                    }`}
                    style={{ width: `${progressPercentage}%` }}
                  />
                </div>

                <button
                  onClick={() => handleCompleteChallenge(challenge)}
                  disabled={isCompleted || challenge.progress < challenge.total}
                  className={`w-full py-2 rounded-lg transition-colors ${
                    isCompleted
                      ? 'bg-green-500 text-white cursor-not-allowed'
                      : challenge.progress >= challenge.total
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                >
                  {isCompleted ? 'Complété !' : 'Compléter'}
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-8 p-4 bg-indigo-50 rounded-lg">
        <p className="text-sm text-indigo-600">
          💡 Complétez tous les défis du jour pour gagner un bonus de 500 points !
        </p>
      </div>
    </div>
  );
}