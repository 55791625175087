import React, { useState } from 'react';
import { useGradeStore } from '../../hooks/useGradeStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { BarChart2, TrendingUp, TrendingDown } from 'lucide-react';

export default function GradeComparison() {
  const { grades, classAverages, addClassAverage } = useGradeStore();
  const { subjects } = useSubjectStore();
  const [selectedSubject, setSelectedSubject] = useState<string>('');

  const calculatePersonalAverage = (subjectId: string) => {
    const subjectGrades = grades.filter(g => g.subjectId === subjectId);
    if (subjectGrades.length === 0) return 0;
    return subjectGrades.reduce((acc, g) => acc + g.value, 0) / subjectGrades.length;
  };

  const getClassAverage = (subjectId: string) => {
    const latest = classAverages
      .filter(avg => avg.subjectId === subjectId)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
    return latest?.value || 0;
  };

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-6">Comparaison avec la classe</h2>
        <div className="space-y-6">
          {subjects.map((subject) => {
            const personalAvg = calculatePersonalAverage(subject.id);
            const classAvg = getClassAverage(subject.id);
            const difference = personalAvg - classAvg;

            return (
              <div key={subject.id}>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: subject.color }}
                    />
                    <span className="font-medium">{subject.name}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    {difference > 0 ? (
                      <TrendingUp className="h-4 w-4 text-green-500" />
                    ) : difference < 0 ? (
                      <TrendingDown className="h-4 w-4 text-red-500" />
                    ) : null}
                    <span className={`text-sm ${
                      difference > 0 ? 'text-green-600' :
                      difference < 0 ? 'text-red-600' :
                      'text-gray-600'
                    }`}>
                      {difference > 0 ? '+' : ''}{difference.toFixed(2)} points
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="p-3 bg-gray-50 rounded-lg">
                    <div className="text-sm text-gray-500">Votre moyenne</div>
                    <div className="text-lg font-semibold">
                      {personalAvg.toFixed(2)}/20
                    </div>
                  </div>
                  <div className="p-3 bg-gray-50 rounded-lg">
                    <div className="text-sm text-gray-500">Moyenne de classe</div>
                    <div className="text-lg font-semibold">
                      {classAvg.toFixed(2)}/20
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Ajouter une moyenne de classe</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Matière
              </label>
              <select
                value={selectedSubject}
                onChange={(e) => setSelectedSubject(e.target.value)}
                className="w-full px-3 py-2 border rounded-md"
              >
                <option value="">Sélectionner une matière</option>
                {subjects.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Moyenne de classe
              </label>
              <input
                type="number"
                min="0"
                max="20"
                step="0.1"
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Entrez la moyenne..."
                onChange={(e) => {
                  if (selectedSubject) {
                    addClassAverage({
                      subjectId: selectedSubject,
                      value: Number(e.target.value),
                      date: new Date().toISOString()
                    });
                    e.target.value = '';
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Statistiques globales</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2 mb-2">
                <BarChart2 className="h-5 w-5 text-indigo-600" />
                <span className="text-sm text-gray-600">Position moyenne</span>
              </div>
              <div className="text-2xl font-bold">
                {subjects.reduce((acc, subject) => {
                  const personalAvg = calculatePersonalAverage(subject.id);
                  const classAvg = getClassAverage(subject.id);
                  return personalAvg > classAvg ? acc + 1 : acc;
                }, 0)}/{subjects.length}
              </div>
              <div className="text-sm text-gray-500">
                matières au-dessus de la moyenne
              </div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2 mb-2">
                <TrendingUp className="h-5 w-5 text-green-600" />
                <span className="text-sm text-gray-600">Progression</span>
              </div>
              <div className="text-2xl font-bold">
                {((subjects.reduce((acc, subject) => {
                  const personalAvg = calculatePersonalAverage(subject.id);
                  const classAvg = getClassAverage(subject.id);
                  return personalAvg > classAvg ? acc + 1 : acc;
                }, 0) / subjects.length) * 100).toFixed(0)}%
              </div>
              <div className="text-sm text-gray-500">
                de réussite globale
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}