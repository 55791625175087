import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { useInitialization } from './hooks/useInitialization';
import Navbar from './components/Navbar';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import TaskDashboard from './pages/TaskDashboard';
import RevisionPage from './pages/RevisionPage';
import WellnessPage from './pages/WellnessPage';
import AnalyticsPage from './pages/AnalyticsPage';
import ScannerPage from './pages/ScannerPage';
import CourseNotesPage from './pages/CourseNotesPage';
import GradingPage from './pages/GradingPage';
import GamificationPage from './pages/GamificationPage';
import AuthModal from './components/auth/AuthModal';

function AppRoutes() {
  useInitialization();

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar />
      <main className="flex-grow">
        <Routes>
          {/* Pages publiques */}
          <Route path="/" element={<Home />} />
          
          {/* Pages protégées */}
          <Route path="/taches" element={<TaskDashboard />} />
          <Route path="/revisions" element={<RevisionPage />} />
          <Route path="/bien-etre" element={<WellnessPage />} />
          <Route path="/analyses" element={<AnalyticsPage />} />
          <Route path="/scanner" element={<ScannerPage />} />
          <Route path="/notes-de-cours" element={<CourseNotesPage />} />
          <Route path="/notation" element={<GradingPage />} />
          <Route path="/recompenses" element={<GamificationPage />} />
        </Routes>
      </main>
      <Footer />
      <AuthModal />
    </div>
  );
}

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;