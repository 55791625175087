import { initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAJo7h03G-X-vEYUr5CsCRDeYDQgT_aZjw",
  authDomain: "appetudiant-48b08.firebaseapp.com",
  projectId: "appetudiant-48b08",
  storageBucket: "appetudiant-48b08.appspot.com",
  messagingSenderId: "80902753919",
  appId: "1:80902753919:web:c129e20125e72a5597b94a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Enable offline persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser doesn\'t support persistence.');
    }
  });

// Set auth persistence
auth.setPersistence(browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting auth persistence:", error);
  });

export { auth, db, storage, app as default };