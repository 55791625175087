import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Subject {
  id: string;
  name: string;
  color: string;
  description?: string;
}

interface SubjectStore {
  subjects: Subject[];
  addSubject: (subject: Omit<Subject, 'id'>) => void;
  updateSubject: (id: string, updates: Partial<Subject>) => void;
  deleteSubject: (id: string) => void;
}

export const useSubjectStore = create<SubjectStore>()(
  persist(
    (set) => ({
      subjects: [], // Store vide par défaut
      
      addSubject: (subject) => set((state) => ({
        subjects: [...state.subjects, { ...subject, id: Date.now().toString() }]
      })),
      
      updateSubject: (id, updates) => set((state) => ({
        subjects: state.subjects.map(subject =>
          subject.id === id ? { ...subject, ...updates } : subject
        )
      })),
      
      deleteSubject: (id) => set((state) => ({
        subjects: state.subjects.filter(subject => subject.id !== id)
      }))
    }),
    {
      name: 'subject-store'
    }
  )
);