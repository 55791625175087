import React from 'react';
import { Helmet } from 'react-helmet-async';

interface PageHeadProps {
  title: string;
  description: string;
  path: string;
}

export default function PageHead({ title, description, path }: PageHeadProps) {
  const fullTitle = `${title} | StudyFlow`;
  const url = `https://studyflow.fr${path}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <link rel="canonical" href={url} />
    </Helmet>
  );
}