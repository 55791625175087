import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { syncStore } from '../services/firebaseSync';
import { useAuth } from '../contexts/AuthContext';

interface Task {
  id: string;
  title: string;
  description?: string;
  subject?: string;
  color?: string;
  priority: 'high' | 'medium' | 'low';
  date: string;
  time?: string;
  notifications?: Array<{ id: number; time: number; unit: string }>;
  isRecurring?: boolean;
  recurringPattern?: 'daily' | 'weekly' | 'monthly';
  status: 'todo' | 'in-progress' | 'done';
}

interface TaskStore {
  tasks: Task[];
  addTask: (task: Omit<Task, 'id'>) => void;
  updateTask: (id: string, updates: Partial<Task>) => void;
  deleteTask: (id: string) => void;
  reset: () => void;
}

export const useTaskStore = create<TaskStore>()(
  persist(
    (set, get) => ({
      tasks: [],
      
      addTask: async (task) => {
        const { currentUser } = useAuth.getState();
        const newTask = { ...task, id: Date.now().toString() };
        
        set((state) => {
          const newTasks = [...state.tasks, newTask];
          if (currentUser) {
            syncStore(currentUser.uid, 'tasks', newTasks);
          }
          return { tasks: newTasks };
        });
      },
      
      updateTask: async (id, updates) => {
        const { currentUser } = useAuth.getState();
        
        set((state) => {
          const newTasks = state.tasks.map(task =>
            task.id === id ? { ...task, ...updates } : task
          );
          if (currentUser) {
            syncStore(currentUser.uid, 'tasks', newTasks);
          }
          return { tasks: newTasks };
        });
      },
      
      deleteTask: async (id) => {
        const { currentUser } = useAuth.getState();
        
        set((state) => {
          const newTasks = state.tasks.filter(task => task.id !== id);
          if (currentUser) {
            syncStore(currentUser.uid, 'tasks', newTasks);
          }
          return { tasks: newTasks };
        });
      },

      reset: () => set({ tasks: [] })
    }),
    {
      name: 'task-store'
    }
  )
);