import React, { useState } from 'react';
import { useGradeStore } from '../../hooks/useGradeStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { BarChart2, TrendingUp, Clock, Target, PieChart, BarChart } from 'lucide-react';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels
);

export default function GradeAnalytics() {
  const { grades } = useGradeStore();
  const { subjects } = useSubjectStore();
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [selectedPeriod, setSelectedPeriod] = useState<'all' | 'month' | 'trimester'>('all');

  const getFilteredGrades = () => {
    let filteredGrades = [...grades];
    
    if (selectedSubject) {
      filteredGrades = filteredGrades.filter(g => g.subjectId === selectedSubject);
    }

    if (selectedPeriod !== 'all') {
      const now = new Date();
      const periodInDays = selectedPeriod === 'month' ? 30 : 90;
      const cutoffDate = new Date(now.setDate(now.getDate() - periodInDays));
      filteredGrades = filteredGrades.filter(g => new Date(g.date) >= cutoffDate);
    }

    return filteredGrades;
  };

  // Distribution des notes par tranches
  const getGradeDistribution = () => {
    const ranges = [
      { min: 0, max: 5 },
      { min: 5, max: 8 },
      { min: 8, max: 10 },
      { min: 10, max: 12 },
      { min: 12, max: 15 },
      { min: 15, max: 18 },
      { min: 18, max: 20 }
    ];

    const filteredGrades = getFilteredGrades();
    const distribution = ranges.map(range => ({
      label: `${range.min}-${range.max}`,
      count: filteredGrades.filter(g => g.value >= range.min && g.value < range.max).length
    }));

    return {
      labels: distribution.map(d => d.label),
      datasets: [{
        label: 'Nombre de notes',
        data: distribution.map(d => d.count),
        backgroundColor: 'rgba(79, 70, 229, 0.8)',
        borderColor: 'rgb(79, 70, 229)',
        borderWidth: 1
      }]
    };
  };

  // Répartition par type d'évaluation
  const getEvaluationTypeDistribution = () => {
    const filteredGrades = getFilteredGrades();
    const typeCount = {
      homework: 0,
      test: 0,
      exam: 0,
      project: 0
    };

    filteredGrades.forEach(grade => {
      typeCount[grade.type as keyof typeof typeCount]++;
    });

    const colors = [
      'rgba(59, 130, 246, 0.8)',
      'rgba(16, 185, 129, 0.8)',
      'rgba(239, 68, 68, 0.8)',
      'rgba(245, 158, 11, 0.8)'
    ];

    return {
      labels: ['Devoirs', 'Contrôles', 'Examens', 'Projets'],
      datasets: [{
        data: Object.values(typeCount),
        backgroundColor: colors,
        borderColor: colors.map(c => c.replace('0.8', '1')),
        borderWidth: 1
      }]
    };
  };

  // Évolution des moyennes par matière
  const getSubjectAveragesOverTime = () => {
    const filteredGrades = getFilteredGrades();
    const sortedGrades = [...filteredGrades].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const datasets = subjects.map((subject) => {
      const subjectGrades = sortedGrades.filter(g => g.subjectId === subject.id);
      const cumulativeAverages = subjectGrades.map((_, idx) => {
        const gradesUpToNow = subjectGrades.slice(0, idx + 1);
        return {
          date: subjectGrades[idx].date,
          average: gradesUpToNow.reduce((acc, g) => acc + g.value, 0) / gradesUpToNow.length
        };
      });

      return {
        label: subject.name,
        data: cumulativeAverages.map(ca => ca.average),
        borderColor: subject.color,
        backgroundColor: `${subject.color}33`,
        fill: true,
        tension: 0.4
      };
    });

    return {
      labels: sortedGrades.map(g => new Date(g.date).toLocaleDateString()),
      datasets
    };
  };

  // Répartition des coefficients
  const getCoefficientDistribution = () => {
    const filteredGrades = getFilteredGrades();
    const coefficients = [...new Set(filteredGrades.map(g => g.coefficient))].sort();
    const data = coefficients.map(coef => ({
      coefficient: coef,
      count: filteredGrades.filter(g => g.coefficient === coef).length
    }));

    return {
      labels: data.map(d => `Coef ${d.coefficient}`),
      datasets: [{
        data: data.map(d => d.count),
        backgroundColor: [
          'rgba(99, 102, 241, 0.8)',
          'rgba(139, 92, 246, 0.8)',
          'rgba(236, 72, 153, 0.8)',
          'rgba(248, 113, 113, 0.8)'
        ]
      }]
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const
      },
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold' as const
        },
        formatter: (value: number) => value > 0 ? value : ''
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const pieChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      datalabels: {
        ...chartOptions.plugins.datalabels,
        formatter: (value: number, ctx: any) => {
          const sum = ctx.dataset.data.reduce((a: number, b: number) => a + b, 0);
          const percentage = (value * 100 / sum).toFixed(1) + '%';
          return percentage;
        }
      }
    }
  };

  const getStatsData = () => {
    const filteredGrades = getFilteredGrades();
    const aboveTen = filteredGrades.filter(g => g.value >= 10).length;
    const total = filteredGrades.length;
    const average = total > 0 ? filteredGrades.reduce((acc, g) => acc + g.value, 0) / total : 0;
    const max = filteredGrades.length > 0 ? Math.max(...filteredGrades.map(g => g.value)) : 0;
    const min = filteredGrades.length > 0 ? Math.min(...filteredGrades.map(g => g.value)) : 0;

    return {
      aboveTen,
      total,
      average,
      max,
      min
    };
  };

  const stats = getStatsData();

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold">Filtres</h2>
          <div className="flex gap-4">
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="px-3 py-2 border rounded-lg"
            >
              <option value="">Toutes les matières</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            <select
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value as 'all' | 'month' | 'trimester')}
              className="px-3 py-2 border rounded-lg"
            >
              <option value="all">Toute la période</option>
              <option value="month">Dernier mois</option>
              <option value="trimester">Dernier trimestre</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Distribution des notes */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-700 mb-4 flex items-center gap-2">
              <BarChart className="h-4 w-4 text-indigo-600" />
              Distribution des notes
            </h3>
            <div className="h-64">
              <Bar data={getGradeDistribution()} options={chartOptions} />
            </div>
          </div>

          {/* Types d'évaluation */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-700 mb-4 flex items-center gap-2">
              <PieChart className="h-4 w-4 text-indigo-600" />
              Types d'évaluation
            </h3>
            <div className="h-64">
              <Doughnut data={getEvaluationTypeDistribution()} options={pieChartOptions} />
            </div>
          </div>

          {/* Évolution des moyennes */}
          <div className="bg-gray-50 rounded-lg p-4 col-span-full">
            <h3 className="text-sm font-medium text-gray-700 mb-4 flex items-center gap-2">
              <TrendingUp className="h-4 w-4 text-indigo-600" />
              Évolution des moyennes
            </h3>
            <div className="h-80">
              <Line 
                data={getSubjectAveragesOverTime()} 
                options={{
                  ...chartOptions,
                  plugins: {
                    ...chartOptions.plugins,
                    datalabels: {
                      display: false
                    }
                  }
                }} 
              />
            </div>
          </div>

          {/* Répartition des coefficients */}
          <div className="bg-gray-50 rounded-lg p-4 col-span-full md:col-span-1">
            <h3 className="text-sm font-medium text-gray-700 mb-4 flex items-center gap-2">
              <BarChart2 className="h-4 w-4 text-indigo-600" />
              Répartition des coefficients
            </h3>
            <div className="h-64">
              <Pie data={getCoefficientDistribution()} options={pieChartOptions} />
            </div>
          </div>

          {/* Statistiques détaillées */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-700 mb-4 flex items-center gap-2">
              <Target className="h-4 w-4 text-indigo-600" />
              Statistiques détaillées
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-white p-3 rounded-lg">
                <div className="text-sm text-gray-600">Moyenne générale</div>
                <div className="text-xl font-bold text-indigo-600">
                  {stats.average.toFixed(2)}/20
                </div>
              </div>
              <div className="bg-white p-3 rounded-lg">
                <div className="text-sm text-gray-600">Notes supérieures à 10</div>
                <div className="text-xl font-bold text-indigo-600">
                  {stats.total > 0 ? ((stats.aboveTen / stats.total) * 100).toFixed(1) : '0'}%
                </div>
              </div>
              <div className="bg-white p-3 rounded-lg">
                <div className="text-sm text-gray-600">Meilleure note</div>
                <div className="text-xl font-bold text-indigo-600">
                  {stats.max.toFixed(1)}/20
                </div>
              </div>
              <div className="bg-white p-3 rounded-lg">
                <div className="text-sm text-gray-600">Note la plus basse</div>
                <div className="text-xl font-bold text-indigo-600">
                  {stats.min.toFixed(1)}/20
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}