import React from 'react';
import { Quote, RefreshCw, Heart } from 'lucide-react';

const quotes = [
  {
    text: "Le succès, c'est tomber sept fois et se relever huit.",
    author: "Proverbe japonais"
  },
  {
    text: "Le meilleur moment pour planter un arbre était il y a 20 ans. Le deuxième meilleur moment est maintenant.",
    author: "Proverbe chinois"
  },
  {
    text: "Le voyage de mille lieues commence par un pas.",
    author: "Lao Tseu"
  }
];

export default function DailyQuote() {
  const [currentQuote, setCurrentQuote] = React.useState(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  });
  const [liked, setLiked] = React.useState(false);

  const refreshQuote = () => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * quotes.length);
    } while (quotes[newIndex].text === currentQuote.text);
    setCurrentQuote(quotes[newIndex]);
    setLiked(false);
  };

  return (
    <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-2xl p-8 text-white">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <Quote className="h-6 w-6" />
          <h2 className="text-xl font-semibold">Citation du jour</h2>
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={() => setLiked(!liked)}
            className={`p-2 rounded-full transition-colors ${
              liked ? 'bg-pink-500 text-white' : 'bg-white/10 hover:bg-white/20'
            }`}
          >
            <Heart className={`h-5 w-5 ${liked ? 'fill-current' : ''}`} />
          </button>
          <button
            onClick={refreshQuote}
            className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
          >
            <RefreshCw className="h-5 w-5" />
          </button>
        </div>
      </div>

      <blockquote className="relative">
        <p className="text-xl font-medium mb-4 leading-relaxed">
          "{currentQuote.text}"
        </p>
        <footer className="text-sm text-indigo-100">
          — {currentQuote.author}
        </footer>
      </blockquote>
    </div>
  );
}