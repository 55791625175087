import { createSyncedStore } from '../hooks/useFirebaseSync';

export interface Task {
  id: string;
  title: string;
  description?: string;
  subject?: string;
  color?: string;
  priority: 'high' | 'medium' | 'low';
  date: string;
  time?: string;
  notifications?: Array<{ id: number; time: number; unit: string }>;
  isRecurring?: boolean;
  recurringPattern?: 'daily' | 'weekly' | 'monthly';
  status: 'todo' | 'in-progress' | 'done';
}

export const useTaskStore = createSyncedStore<Task>('tasks');