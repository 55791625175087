import React, { useState } from 'react';
import { useNotesStore } from '../../hooks/useNotesStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { Search, Tag, Clock, AlertCircle, Edit2, Trash2, History, Eye } from 'lucide-react';
import VersionHistory from './VersionHistory';
import NoteEditor from './NoteEditor';

export default function NotesList() {
  const { notes, deleteNote } = useNotesStore();
  const { subjects } = useSubjectStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showVersionHistory, setShowVersionHistory] = useState<string | null>(null);
  const [editingNote, setEditingNote] = useState<string | null>(null);
  const [viewingNote, setViewingNote] = useState<string | null>(null);

  // Get unique tags from all notes
  const allTags = Array.from(new Set(notes.flatMap(note => note.tags)));

  const filteredNotes = notes.filter(note => {
    const matchesSearch = note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      note.content.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSubject = !selectedSubject || note.subjectId === selectedSubject;
    const matchesTags = selectedTags.length === 0 ||
      selectedTags.every(tag => note.tags.includes(tag));
    return matchesSearch && matchesSubject && matchesTags;
  });

  const sortedNotes = [...filteredNotes].sort((a, b) => {
    // Sort by urgency first
    const urgencyOrder = { high: 3, medium: 2, low: 1 };
    const urgencyDiff = urgencyOrder[b.urgency] - urgencyOrder[a.urgency];
    if (urgencyDiff !== 0) return urgencyDiff;

    // Then by importance
    const importanceOrder = { high: 3, medium: 2, low: 1 };
    const importanceDiff = importanceOrder[b.importance] - importanceOrder[a.importance];
    if (importanceDiff !== 0) return importanceDiff;

    // Finally by date
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });

  if (editingNote) {
    const note = notes.find(n => n.id === editingNote);
    if (!note) return null;

    return (
      <div>
        <div className="mb-4">
          <button
            onClick={() => setEditingNote(null)}
            className="text-gray-600 hover:text-gray-900"
          >
            ← Retour à la liste
          </button>
        </div>
        <NoteEditor initialNote={note} onSave={() => setEditingNote(null)} />
      </div>
    );
  }

  if (viewingNote) {
    const note = notes.find(n => n.id === viewingNote);
    if (!note) return null;

    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-4">
          <button
            onClick={() => setViewingNote(null)}
            className="text-gray-600 hover:text-gray-900"
          >
            ← Retour à la liste
          </button>
        </div>
        <h2 className="text-2xl font-bold mb-4">{note.title}</h2>
        <div className="prose prose-lg max-w-none mb-6" dangerouslySetInnerHTML={{ __html: note.content }} />
        <div className="flex items-center justify-end gap-2">
          <button
            onClick={() => {
              setViewingNote(null);
              setEditingNote(note.id);
            }}
            className="flex items-center gap-2 px-4 py-2 text-indigo-600 hover:bg-indigo-50 rounded-lg"
          >
            <Edit2 className="h-4 w-4" />
            Modifier
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex flex-col sm:flex-row gap-4 mb-6">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Rechercher dans les notes..."
                className="w-full pl-10 pr-4 py-2 border rounded-lg"
              />
            </div>
          </div>
          <div className="flex gap-4">
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="px-3 py-2 border rounded-lg"
            >
              <option value="">Toutes les matières</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-6">
          {allTags.map((tag) => (
            <button
              key={tag}
              onClick={() => setSelectedTags(
                selectedTags.includes(tag)
                  ? selectedTags.filter(t => t !== tag)
                  : [...selectedTags, tag]
              )}
              className={`inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm ${
                selectedTags.includes(tag)
                  ? 'bg-indigo-100 text-indigo-800'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              <Tag className="h-4 w-4" />
              {tag}
            </button>
          ))}
        </div>

        <div className="space-y-4">
          {sortedNotes.map((note) => {
            const subject = subjects.find(s => s.id === note.subjectId);
            return (
              <div
                key={note.id}
                className="p-4 border rounded-lg hover:bg-gray-50"
                style={{ borderLeft: `4px solid ${subject?.color || '#4F46E5'}` }}
              >
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3 className="font-medium text-lg">{note.title}</h3>
                    <div className="flex items-center gap-4 text-sm text-gray-500">
                      {subject && (
                        <span className="flex items-center gap-1">
                          <div
                            className="w-2 h-2 rounded-full"
                            style={{ backgroundColor: subject.color }}
                          />
                          {subject.name}
                        </span>
                      )}
                      <span className="flex items-center gap-1">
                        <Clock className="h-4 w-4" />
                        {new Date(note.updatedAt).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => setViewingNote(note.id)}
                      className="p-1 text-gray-400 hover:text-indigo-600 rounded"
                    >
                      <Eye className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => setShowVersionHistory(note.id)}
                      className="p-1 text-gray-400 hover:text-indigo-600 rounded"
                    >
                      <History className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => setEditingNote(note.id)}
                      className="p-1 text-gray-400 hover:text-indigo-600 rounded"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => deleteNote(note.id)}
                      className="p-1 text-gray-400 hover:text-red-500 rounded"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>

                <div className="flex flex-wrap gap-2 mb-2">
                  {note.tags.map((tag) => (
                    <span
                      key={tag}
                      className="px-2 py-1 bg-gray-100 rounded-full text-xs text-gray-600"
                    >
                      {tag}
                    </span>
                  ))}
                </div>

                <div className="flex items-center gap-4">
                  <span className={`inline-flex items-center gap-1 px-2 py-1 rounded text-xs font-medium ${
                    note.importance === 'high' ? 'bg-red-100 text-red-800' :
                    note.importance === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-blue-100 text-blue-800'
                  }`}>
                    <AlertCircle className="h-3 w-3" />
                    Importance: {note.importance}
                  </span>
                  <span className={`inline-flex items-center gap-1 px-2 py-1 rounded text-xs font-medium ${
                    note.urgency === 'high' ? 'bg-red-100 text-red-800' :
                    note.urgency === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-blue-100 text-blue-800'
                  }`}>
                    <Clock className="h-3 w-3" />
                    Urgence: {note.urgency}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showVersionHistory && (
        <VersionHistory
          noteId={showVersionHistory}
          onClose={() => setShowVersionHistory(null)}
        />
      )}
    </div>
  );
}