import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, SkipBack, SkipForward, Volume2 } from 'lucide-react';
import { Howl } from 'howler';

// Méditations avec des URLs fonctionnelles
const meditations = [
  {
    id: '1',
    title: 'Méditation guidée pour la concentration',
    duration: '10:00',
    category: 'Concentration',
    audioUrl: 'https://cdn.pixabay.com/download/audio/2022/05/27/audio_1808fbf07a.mp3'
  },
  {
    id: '2',
    title: 'Respiration anti-stress',
    duration: '5:00',
    category: 'Relaxation',
    audioUrl: 'https://cdn.pixabay.com/download/audio/2022/10/30/audio_347ca2a53d.mp3'
  },
  {
    id: '3',
    title: 'Préparation mentale aux examens',
    duration: '15:00',
    category: 'Performance',
    audioUrl: 'https://cdn.pixabay.com/download/audio/2022/08/02/audio_884fe92c21.mp3'
  }
];

export default function MeditationPlayer() {
  const [currentTrack, setCurrentTrack] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const soundRef = useRef<Howl | null>(null);

  useEffect(() => {
    if (soundRef.current) {
      soundRef.current.unload();
    }

    soundRef.current = new Howl({
      src: [meditations[currentTrack].audioUrl],
      volume: volume,
      onplay: () => setIsPlaying(true),
      onpause: () => setIsPlaying(false),
      onend: () => {
        setIsPlaying(false);
        setCurrentTime(0);
      },
      onload: () => {
        if (soundRef.current) {
          setDuration(soundRef.current.duration());
        }
      }
    });

    return () => {
      if (soundRef.current) {
        soundRef.current.unload();
      }
    };
  }, [currentTrack]);

  const togglePlay = () => {
    if (soundRef.current) {
      if (isPlaying) {
        soundRef.current.pause();
      } else {
        soundRef.current.play();
      }
    }
  };

  const handlePrevious = () => {
    if (currentTrack > 0) {
      setCurrentTrack(currentTrack - 1);
      setIsPlaying(false);
      setCurrentTime(0);
    }
  };

  const handleNext = () => {
    if (currentTrack < meditations.length - 1) {
      setCurrentTrack(currentTrack + 1);
      setIsPlaying(false);
      setCurrentTime(0);
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (soundRef.current) {
      soundRef.current.volume(newVolume);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (soundRef.current && isPlaying) {
        setCurrentTime(soundRef.current.seek());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isPlaying]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <h2 className="text-xl font-semibold mb-6">Méditation guidée</h2>

      <div className="space-y-6">
        <div className="text-center">
          <h3 className="text-lg font-medium mb-2">
            {meditations[currentTrack].title}
          </h3>
          <p className="text-sm text-gray-500">
            {meditations[currentTrack].category} • {meditations[currentTrack].duration}
          </p>
        </div>

        {/* Progress Bar */}
        <div className="relative w-full h-1 bg-gray-200 rounded-full">
          <div
            className="absolute h-full bg-indigo-600 rounded-full"
            style={{
              width: `${(currentTime / duration) * 100}%`,
              transition: 'width 0.1s linear'
            }}
          />
        </div>

        <div className="flex justify-between text-sm text-gray-500">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>

        {/* Controls */}
        <div className="flex items-center justify-center gap-6">
          <button
            onClick={handlePrevious}
            disabled={currentTrack === 0}
            className="p-2 text-gray-400 hover:text-gray-600 disabled:opacity-50"
          >
            <SkipBack className="h-6 w-6" />
          </button>

          <button
            onClick={togglePlay}
            className="p-4 bg-indigo-600 text-white rounded-full hover:bg-indigo-700"
          >
            {isPlaying ? (
              <Pause className="h-6 w-6" />
            ) : (
              <Play className="h-6 w-6" />
            )}
          </button>

          <button
            onClick={handleNext}
            disabled={currentTrack === meditations.length - 1}
            className="p-2 text-gray-400 hover:text-gray-600 disabled:opacity-50"
          >
            <SkipForward className="h-6 w-6" />
          </button>
        </div>

        {/* Volume Control */}
        <div className="flex items-center gap-2">
          <Volume2 className="h-4 w-4 text-gray-400" />
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onChange={handleVolumeChange}
            className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-indigo-600"
          />
        </div>
      </div>

      {/* Playlist */}
      <div className="mt-8">
        <h3 className="font-medium mb-4">Liste des méditations</h3>
        <div className="space-y-2">
          {meditations.map((meditation, index) => (
            <button
              key={meditation.id}
              onClick={() => {
                setCurrentTrack(index);
                setIsPlaying(false);
                setCurrentTime(0);
              }}
              className={`w-full p-3 text-left rounded-lg transition-colors ${
                currentTrack === index
                  ? 'bg-indigo-50 text-indigo-600'
                  : 'hover:bg-gray-50'
              }`}
            >
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-medium">{meditation.title}</p>
                  <p className="text-sm text-gray-500">{meditation.category}</p>
                </div>
                <span className="text-sm text-gray-500">{meditation.duration}</span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}