import React from 'react';
import { Trophy, Star, Target, Award, CheckCircle } from 'lucide-react';
import { useRewardsStore } from '../../hooks/useRewardsStore';
import { useAchievementRules } from '../../hooks/useAchievementRules';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function AchievementTimeline() {
  const { achievements } = useRewardsStore();
  const { rules } = useAchievementRules();

  // Filtrer les règles pour n'afficher que les achievements débloqués
  const unlockedAchievements = rules.filter(rule => 
    achievements.includes(rule.id)
  ).map(achievement => ({
    ...achievement,
    date: new Date().toISOString(), // Dans une vraie application, cette date viendrait de la base de données
    icon: <Trophy className="h-5 w-5" />,
    color: 'bg-indigo-600'
  }));

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <h2 className="text-xl font-semibold mb-8">Historique des réalisations</h2>

      <div className="relative">
        {/* Ligne verticale */}
        <div className="absolute left-8 top-0 bottom-0 w-px bg-gray-200" />

        {/* Réalisations */}
        <div className="space-y-8">
          {unlockedAchievements.map((achievement, index) => (
            <div
              key={achievement.id}
              className={`relative flex items-start gap-6 ${
                index === unlockedAchievements.length - 1 ? '' : 'pb-8'
              }`}
            >
              {/* Icône */}
              <div className={`relative z-10 ${achievement.color} text-white p-3 rounded-full`}>
                {achievement.icon}
              </div>

              {/* Contenu */}
              <div className="flex-1 bg-gray-50 rounded-xl p-6">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium text-gray-900">{achievement.title}</h3>
                  <span className="text-sm text-gray-500">
                    {format(new Date(achievement.date), 'dd MMMM yyyy', { locale: fr })}
                  </span>
                </div>
                <p className="text-gray-600">{achievement.description}</p>
                <div className="mt-4 flex items-center gap-2 text-green-600">
                  <CheckCircle className="h-4 w-4" />
                  <span className="text-sm font-medium">
                    +{achievement.points} points
                  </span>
                </div>
              </div>
            </div>
          ))}

          {unlockedAchievements.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              Aucune réalisation débloquée pour le moment.
              Continuez vos efforts pour débloquer des récompenses !
            </div>
          )}
        </div>
      </div>

      {/* Message d'encouragement */}
      <div className="mt-8 p-4 bg-indigo-50 rounded-lg">
        <p className="text-sm text-indigo-600">
          🎉 {unlockedAchievements.length > 0 
            ? 'Continuez comme ça ! Chaque réalisation vous rapproche de vos objectifs.'
            : 'Commencez à utiliser StudyFlow pour débloquer vos premières réalisations !'}
        </p>
      </div>
    </div>
  );
}