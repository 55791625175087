import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Calendar,
  BarChart2,
  BookOpen,
  FileText,
  Layout,
  Users,
  ArrowRight
} from 'lucide-react';

const features = [
  {
    icon: <Calendar className="h-6 w-6" />,
    title: "Calendrier Scolaire en Ligne",
    description: "Planifiez facilement vos cours, examens et devoirs grâce au calendrier scolaire interactif de Studyflow.fr. Ne manquez plus aucune échéance importante et optimisez votre emploi du temps pour une meilleure gestion de vos études.",
    color: "from-blue-500 to-indigo-600",
    link: "/studyflow/taches"
  },
  {
    icon: <BarChart2 className="h-6 w-6" />,
    title: "Statistiques de Notes Personnalisées",
    description: "Suivez votre progression académique en temps réel. Notre outil génère des statistiques détaillées de vos notes, vous aidant à identifier vos points forts et les domaines nécessitant une amélioration.",
    color: "from-purple-500 to-pink-600",
    link: "/studyflow/notation"
  },
  {
    icon: <BookOpen className="h-6 w-6" />,
    title: "Outils de Révision Efficaces",
    description: "Accédez à des ressources de révision personnalisées pour chaque matière. Maximisez votre temps d'étude avec des fiches de révision, des quiz interactifs et des conseils adaptés à vos besoins.",
    color: "from-green-500 to-emerald-600",
    link: "/studyflow/revisions"
  },
  {
    icon: <FileText className="h-6 w-6" />,
    title: "Prise de Notes en Ligne",
    description: "Centralisez vos notes de cours et vos idées grâce à notre outil de prise de notes en ligne. Organisez-les par matière ou par date pour un accès facile et rapide, où que vous soyez.",
    color: "from-amber-500 to-orange-600",
    link: "/studyflow/notes-de-cours"
  },
  {
    icon: <Layout className="h-6 w-6" />,
    title: "Tableau de Bord Intuitif",
    description: "Obtenez une vue d'ensemble de vos performances académiques avec notre dashboard personnalisé. Prenez des décisions éclairées pour améliorer vos résultats grâce à l'analyse de données avancée.",
    color: "from-red-500 to-rose-600",
    link: "/studyflow/analyses"
  },
  {
    icon: <Users className="h-6 w-6" />,
    title: "Outil Dédié aux Parents Engagés",
    description: "Suivez la progression scolaire de vos enfants. Accédez aux statistiques de notes, au calendrier des devoirs et aux ressources de révision pour mieux les accompagner vers la réussite.",
    color: "from-cyan-500 to-teal-600",
    link: "/signup"
  }
];

export default function Home() {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center"
              >
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block">Améliorez Vos Résultats Scolaires</span>
                  <span className="block text-indigo-600">avec Studyflow.fr, Votre Plateforme Tout-en-Un</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl">
                  Calendrier, Statistiques de Notes, Révisions et Plus Encore pour Collégiens, Lycéens, Étudiants et Parents
                </p>
                <div className="mt-5 sm:mt-8 flex justify-center">
                  <Link
                    to="/signup"
                    className="flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-xl text-white bg-gradient-to-r from-indigo-600 to-blue-600 hover:from-indigo-700 hover:to-blue-700 md:py-4 md:text-lg md:px-10 transition-all duration-200 shadow-lg hover:shadow-xl"
                  >
                    Commencez Maintenant sur Studyflow.fr
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </Link>
                </div>
              </motion.div>
            </main>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="relative group"
              >
                <Link to={feature.link} className="block">
                  <div className="h-full relative bg-white rounded-2xl shadow-sm p-8 hover:shadow-lg transition-all duration-200">
                    <div className={`absolute top-0 left-0 w-full h-1 bg-gradient-to-r ${feature.color} rounded-t-2xl`} />
                    <div className="flex flex-col h-full">
                      <div className={`p-3 rounded-xl bg-gradient-to-r ${feature.color} text-white w-fit`}>
                        {feature.icon}
                      </div>
                      <h3 className="mt-4 text-xl font-semibold text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-gray-600 flex-grow">
                        {feature.description}
                      </p>
                      <div className="mt-4 flex items-center text-indigo-600 font-medium">
                        En savoir plus
                        <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-2" />
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-indigo-600 to-blue-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Rejoignez la Communauté de Studyflow.fr Aujourd'hui
            </h2>
            <p className="mt-4 text-xl text-indigo-100">
              Inscrivez-vous gratuitement sur Studyflow.fr et découvrez comment notre plateforme peut transformer votre expérience scolaire. Rejoignez les nombreux étudiants et parents qui nous font déjà confiance pour améliorer leurs résultats scolaires.
            </p>
            <div className="mt-8">
              <Link
                to="/signup"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-xl text-indigo-600 bg-white hover:bg-indigo-50 md:py-4 md:text-lg md:px-10 transition-all duration-200"
              >
                Commencer Maintenant
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}