import React, { useState } from 'react';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { useRevisionStore } from '../../hooks/useRevisionStore';
import { Plus, Calendar, Clock, Target, Book } from 'lucide-react';
import RevisionForm from './RevisionForm';

export default function RevisionPlanner() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { subjects } = useSubjectStore();
  const { revisionSessions, addSession } = useRevisionStore();

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold">Planning de Révision</h2>
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4" />
            Nouvelle session
          </button>
        </div>

        <div className="space-y-4">
          {revisionSessions.map((session) => {
            const subject = subjects.find(s => s.id === session.subjectId);
            return (
              <div
                key={session.id}
                className="p-4 border rounded-lg hover:bg-gray-50"
                style={{ borderLeft: `4px solid ${subject?.color || '#4F46E5'}` }}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium text-gray-900">{session.title}</h3>
                    <p className="text-sm text-gray-600 mt-1">
                      {session.description}
                    </p>
                  </div>
                  <span className={`px-2 py-1 rounded text-xs font-medium ${
                    session.priority === 'high' ? 'bg-red-100 text-red-800' :
                    session.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-blue-100 text-blue-800'
                  }`}>
                    {session.priority}
                  </span>
                </div>

                <div className="mt-4 flex items-center gap-4 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <Calendar className="h-4 w-4" />
                    {session.date}
                  </div>
                  <div className="flex items-center gap-1">
                    <Clock className="h-4 w-4" />
                    {session.duration} min
                  </div>
                  <div className="flex items-center gap-1">
                    <Target className="h-4 w-4" />
                    {session.objective}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Objectifs de la semaine</h3>
          <div className="space-y-4">
            {subjects.map((subject) => {
              const sessionCount = revisionSessions.filter(
                s => s.subjectId === subject.id
              ).length;
              return (
                <div
                  key={subject.id}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center gap-3">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: subject.color }}
                    />
                    <span className="font-medium">{subject.name}</span>
                  </div>
                  <div className="text-sm text-gray-600">
                    {sessionCount} session{sessionCount !== 1 ? 's' : ''} planifiée{sessionCount !== 1 ? 's' : ''}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Conseils de révision</h3>
          <div className="space-y-3">
            <div className="flex items-start gap-3 p-3 bg-indigo-50 rounded-lg">
              <Book className="h-5 w-5 text-indigo-600 flex-shrink-0 mt-0.5" />
              <div>
                <p className="text-sm font-medium text-gray-900">
                  Technique active de rappel
                </p>
                <p className="text-sm text-gray-600">
                  Testez vos connaissances régulièrement plutôt que de relire passivement
                </p>
              </div>
            </div>
            <div className="flex items-start gap-3 p-3 bg-green-50 rounded-lg">
              <Clock className="h-5 w-5 text-green-600 flex-shrink-0 mt-0.5" />
              <div>
                <p className="text-sm font-medium text-gray-900">
                  Espacez vos révisions
                </p>
                <p className="text-sm text-gray-600">
                  Révisez le même sujet à intervalles croissants pour une meilleure rétention
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <RevisionForm
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={addSession}
        />
      )}
    </div>
  );
}