import React from 'react';
import { useBadgeRules } from '../../hooks/useBadgeRules';
import { useRewardsStore } from '../../hooks/useRewardsStore';
import { motion } from 'framer-motion';
import { Lock, Check } from 'lucide-react';

export default function BadgeSystem() {
  const { rules } = useBadgeRules();
  const { badges } = useRewardsStore();

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <h2 className="text-xl font-semibold mb-8">Badges et Récompenses</h2>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {rules.map((badge) => {
          const isUnlocked = badges.includes(badge.id);
          
          return (
            <motion.div
              key={badge.id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`relative p-6 rounded-2xl ${
                isUnlocked 
                  ? 'bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border-2 border-indigo-500'
                  : 'bg-gray-100 border-2 border-transparent'
              }`}
            >
              {/* Badge Icon */}
              <div className="relative w-16 h-16 mx-auto mb-4">
                <div 
                  className={`w-full h-full rounded-full flex items-center justify-center text-2xl ${
                    isUnlocked ? 'bg-gradient-to-br from-indigo-500 to-purple-500' : 'bg-gray-300'
                  }`}
                >
                  {badge.icon}
                </div>
                {isUnlocked && (
                  <div className="absolute -top-1 -right-1 bg-green-500 rounded-full p-1">
                    <Check className="w-4 h-4 text-white" />
                  </div>
                )}
                {!isUnlocked && (
                  <div className="absolute -top-1 -right-1 bg-gray-400 rounded-full p-1">
                    <Lock className="w-4 h-4 text-white" />
                  </div>
                )}
              </div>

              {/* Badge Info */}
              <div className="text-center">
                <h3 className={`font-semibold mb-1 ${
                  isUnlocked ? 'text-gray-900' : 'text-gray-500'
                }`}>
                  {badge.title}
                </h3>
                <p className="text-sm text-gray-500">
                  {badge.description}
                </p>
              </div>

              {/* Progress Indicator */}
              {!isUnlocked && (
                <div className="mt-4">
                  <div className="w-full h-1 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-indigo-500 rounded-full transition-all duration-500"
                      style={{ width: '0%' }}
                    />
                  </div>
                  <p className="text-xs text-center text-gray-500 mt-1">
                    En cours...
                  </p>
                </div>
              )}
            </motion.div>
          );
        })}
      </div>

      {/* Statistiques */}
      <div className="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
        <div className="p-4 bg-indigo-50 rounded-xl">
          <div className="text-2xl font-bold text-indigo-600">
            {badges.length}/{rules.length}
          </div>
          <p className="text-sm text-gray-600">Badges débloqués</p>
        </div>
        
        <div className="p-4 bg-purple-50 rounded-xl">
          <div className="text-2xl font-bold text-purple-600">
            {Math.round((badges.length / rules.length) * 100)}%
          </div>
          <p className="text-sm text-gray-600">Progression totale</p>
        </div>

        <div className="p-4 bg-green-50 rounded-xl">
          <div className="text-2xl font-bold text-green-600">
            {badges.length * 100}
          </div>
          <p className="text-sm text-gray-600">Points gagnés</p>
        </div>

        <div className="p-4 bg-amber-50 rounded-xl">
          <div className="text-2xl font-bold text-amber-600">
            {rules.length - badges.length}
          </div>
          <p className="text-sm text-gray-600">Badges restants</p>
        </div>
      </div>

      {/* Message d'encouragement */}
      <div className="mt-8 p-4 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-xl">
        <p className="text-sm text-indigo-600 text-center">
          {badges.length === 0 
            ? "Commencez à utiliser StudyFlow pour débloquer vos premiers badges !" 
            : "Continuez vos efforts pour débloquer tous les badges !"}
        </p>
      </div>
    </div>
  );
}