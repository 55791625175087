import { useTaskStore } from './useTaskStore';
import { useGradeStore } from './useGradeStore';
import { useRevisionStore } from './useRevisionStore';
import { useRewardsStore } from './useRewardsStore';

export interface AchievementRule {
  id: string;
  title: string;
  description: string;
  points: number;
  check: () => boolean;
}

export const useAchievementRules = () => {
  const { tasks } = useTaskStore();
  const { grades } = useGradeStore();
  const { revisionSessions } = useRevisionStore();
  const { streakDays } = useRewardsStore();

  const rules: AchievementRule[] = [
    {
      id: 'first-task',
      title: 'Premier pas',
      description: 'Créez votre première tâche',
      points: 50,
      check: () => tasks.length > 0
    },
    {
      id: 'task-master',
      title: 'Maître des tâches',
      description: 'Complétez 10 tâches',
      points: 100,
      check: () => tasks.filter(t => t.status === 'done').length >= 10
    },
    {
      id: 'grade-achiever',
      title: 'Excellence académique',
      description: 'Obtenez une note supérieure à 15/20',
      points: 150,
      check: () => grades.some(g => g.value > 15)
    },
    {
      id: 'study-streak',
      title: 'Régularité exemplaire',
      description: '7 jours consécutifs d\'activité',
      points: 200,
      check: () => streakDays >= 7
    },
    {
      id: 'revision-master',
      title: 'Maître des révisions',
      description: 'Complétez 5 sessions de révision',
      points: 150,
      check: () => revisionSessions.filter(s => s.completed).length >= 5
    }
  ];

  const checkAchievements = async (userId?: string) => {
    const rewardsStore = useRewardsStore.getState();
    
    for (const rule of rules) {
      if (rule.check() && !rewardsStore.achievements.includes(rule.id)) {
        await rewardsStore.unlockAchievement(rule.id, userId);
        await rewardsStore.addPoints(rule.points, userId);
      }
    }
  };

  return {
    rules,
    checkAchievements
  };
};