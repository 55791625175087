import React, { useState } from 'react';
import { useGradeStore } from '../../hooks/useGradeStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { Plus, Target, Check, X, Edit2, Trash2 } from 'lucide-react';

export default function GradeGoals() {
  const { goals, addGoal, updateGoal, deleteGoal } = useGradeStore();
  const { subjects } = useSubjectStore();
  const [isAdding, setIsAdding] = useState(false);
  const [newGoal, setNewGoal] = useState({
    subjectId: '',
    targetValue: 10,
    deadline: '',
    achieved: false
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addGoal(newGoal);
    setIsAdding(false);
    setNewGoal({
      subjectId: '',
      targetValue: 10,
      deadline: '',
      achieved: false
    });
  };

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold">Objectifs</h2>
          <button
            onClick={() => setIsAdding(true)}
            className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4" />
            Nouvel objectif
          </button>
        </div>

        {isAdding && (
          <form onSubmit={handleSubmit} className="mb-6 p-4 border rounded-lg bg-gray-50">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Matière
                </label>
                <select
                  value={newGoal.subjectId}
                  onChange={(e) => setNewGoal({ ...newGoal, subjectId: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Sélectionner une matière</option>
                  {subjects.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                      {subject.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Note cible
                </label>
                <input
                  type="number"
                  min="0"
                  max="20"
                  step="0.5"
                  value={newGoal.targetValue}
                  onChange={(e) => setNewGoal({ ...newGoal, targetValue: Number(e.target.value) })}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date limite
                </label>
                <input
                  type="date"
                  value={newGoal.deadline}
                  onChange={(e) => setNewGoal({ ...newGoal, deadline: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>

              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setIsAdding(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </form>
        )}

        <div className="space-y-4">
          {goals.map((goal) => {
            const subject = subjects.find(s => s.id === goal.subjectId);
            return (
              <div
                key={goal.id}
                className={`p-4 border rounded-lg ${
                  goal.achieved ? 'bg-green-50 border-green-200' : 'hover:bg-gray-50'
                }`}
                style={{ borderLeft: `4px solid ${subject?.color || '#4F46E5'}` }}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">{subject?.name}</span>
                      <span className="text-2xl font-bold">{goal.targetValue}/20</span>
                    </div>
                    <div className="text-sm text-gray-600 mt-1">
                      Date limite : {new Date(goal.deadline).toLocaleDateString()}
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => updateGoal(goal.id, { achieved: !goal.achieved })}
                      className={`p-1 rounded ${
                        goal.achieved
                          ? 'text-green-600 hover:bg-green-100'
                          : 'text-gray-400 hover:text-green-600 hover:bg-gray-100'
                      }`}
                    >
                      <Check className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => deleteGoal(goal.id)}
                      className="p-1 text-gray-400 hover:text-red-500 hover:bg-gray-100 rounded"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Progression</h2>
          <div className="space-y-6">
            {subjects.map((subject) => {
              const subjectGoals = goals.filter(g => g.subjectId === subject.id);
              const achievedGoals = subjectGoals.filter(g => g.achieved);
              const progress = subjectGoals.length > 0
                ? (achievedGoals.length / subjectGoals.length) * 100
                : 0;

              return (
                <div key={subject.id}>
                  <div className="flex justify-between mb-2">
                    <div className="flex items-center gap-2">
                      <div
                        className="w-3 h-3 rounded-full"
                        style={{ backgroundColor: subject.color }}
                      />
                      <span className="font-medium">{subject.name}</span>
                    </div>
                    <span className="text-sm text-gray-500">
                      {achievedGoals.length}/{subjectGoals.length} objectifs
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-indigo-600 h-2 rounded-full transition-all duration-500"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Statistiques des objectifs</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-600 mb-1">Objectifs atteints</div>
              <div className="text-2xl font-bold text-green-600">
                {goals.filter(g => g.achieved).length}/{goals.length}
              </div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-600 mb-1">Taux de réussite</div>
              <div className="text-2xl font-bold text-indigo-600">
                {goals.length > 0
                  ? Math.round((goals.filter(g => g.achieved).length / goals.length) * 100)
                  : 0}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}