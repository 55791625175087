import React from 'react';
import { useTaskStore } from '../../hooks/useTaskStore';
import { BookOpen, GraduationCap, FileText } from 'lucide-react';
import { startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function TaskStats() {
  const { tasks } = useTaskStore();
  const now = new Date();
  const weekStart = startOfWeek(now, { locale: fr });
  const weekEnd = endOfWeek(now, { locale: fr });

  const weeklyTasks = tasks.filter(task => {
    const taskDate = new Date(task.date);
    return isWithinInterval(taskDate, { start: weekStart, end: weekEnd });
  });

  const examCount = weeklyTasks.filter(task => task.type === 'exam').length;
  const assignmentCount = weeklyTasks.filter(task => task.type === 'assignment').length;
  const uniqueSubjects = [...new Set(weeklyTasks.map(task => task.subject))].length;

  const stats = [
    {
      label: 'Examens de la semaine',
      value: examCount,
      icon: <GraduationCap className="h-5 w-5 text-red-500" />,
      change: `${examCount} à venir`
    },
    {
      label: 'Devoirs de la semaine',
      value: assignmentCount,
      icon: <FileText className="h-5 w-5 text-blue-500" />,
      change: `${assignmentCount} à rendre`
    },
    {
      label: 'Matières de la semaine',
      value: uniqueSubjects,
      icon: <BookOpen className="h-5 w-5 text-green-500" />,
      change: `${uniqueSubjects} matières concernées`
    }
  ];

  return (
    <div className="flex gap-4">
      {stats.map((stat) => (
        <div
          key={stat.label}
          className="bg-white p-4 rounded-lg shadow-sm flex items-center gap-4"
        >
          <div className="p-2 rounded-full bg-gray-50">
            {stat.icon}
          </div>
          <div>
            <p className="text-sm text-gray-600">{stat.label}</p>
            <p className="text-2xl font-semibold">{stat.value}</p>
            <p className="text-xs text-gray-500">{stat.change}</p>
          </div>
        </div>
      ))}
    </div>
  );
}