import React from 'react';
import PageHead from '../components/layout/PageHead';
import DailyQuote from '../components/wellness/DailyQuote';
import MoodTracker from '../components/wellness/MoodTracker';
import MeditationPlayer from '../components/wellness/MeditationPlayer';
import StressManagement from '../components/wellness/StressManagement';
import BreakReminder from '../components/wellness/BreakReminder';
import MotivationalStats from '../components/wellness/MotivationalStats';

export default function WellnessPage() {
  return (
    <>
      <PageHead
        title="Bien-être et Gestion du Stress"
        description="Gérez votre stress et maintenez un équilibre sain dans vos études avec les outils de bien-être StudyFlow. Méditation, exercices de respiration et conseils personnalisés."
        path="/studyflow/bien-etre"
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Espace Bien-être
        </h1>

        <div className="grid gap-8">
          {/* Citation motivante et statistiques */}
          <div className="grid gap-8 md:grid-cols-2">
            <DailyQuote />
            <MotivationalStats />
          </div>

          {/* Suivi de l'humeur et méditation */}
          <div className="grid gap-8 md:grid-cols-2">
            <MoodTracker />
            <MeditationPlayer />
          </div>

          {/* Gestion du stress et rappels de pause */}
          <div className="grid gap-8 md:grid-cols-2">
            <StressManagement />
            <BreakReminder />
          </div>
        </div>
      </div>
    </>
  );
}