import React from 'react';
import { Smile, Frown, Meh, ThumbsUp, ThumbsDown, Heart, Brain } from 'lucide-react';

interface MoodEntry {
  date: string;
  mood: 'great' | 'good' | 'neutral' | 'bad' | 'terrible';
  energy: number;
  stress: number;
  motivation: number;
  note?: string;
}

export default function MoodTracker() {
  const [currentMood, setCurrentMood] = React.useState<MoodEntry>({
    date: new Date().toISOString(),
    mood: 'neutral',
    energy: 5,
    stress: 5,
    motivation: 5
  });

  const moodEmojis = {
    great: { icon: <Smile className="h-8 w-8" />, color: 'text-green-500' },
    good: { icon: <ThumbsUp className="h-8 w-8" />, color: 'text-blue-500' },
    neutral: { icon: <Meh className="h-8 w-8" />, color: 'text-yellow-500' },
    bad: { icon: <ThumbsDown className="h-8 w-8" />, color: 'text-orange-500' },
    terrible: { icon: <Frown className="h-8 w-8" />, color: 'text-red-500' }
  };

  const renderSlider = (
    value: number,
    onChange: (value: number) => void,
    icon: React.ReactNode,
    label: string
  ) => (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {icon}
          <span className="text-sm font-medium text-gray-700">{label}</span>
        </div>
        <span className="text-sm text-gray-500">{value}/10</span>
      </div>
      <input
        type="range"
        min="0"
        max="10"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-indigo-600"
      />
    </div>
  );

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <h2 className="text-xl font-semibold mb-6">Comment vous sentez-vous ?</h2>

      <div className="space-y-8">
        {/* Mood Selection */}
        <div className="flex justify-between">
          {Object.entries(moodEmojis).map(([mood, { icon, color }]) => (
            <button
              key={mood}
              onClick={() => setCurrentMood({ ...currentMood, mood: mood as MoodEntry['mood'] })}
              className={`p-4 rounded-lg transition-all ${
                currentMood.mood === mood
                  ? `${color} bg-gray-100`
                  : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50'
              }`}
            >
              {icon}
            </button>
          ))}
        </div>

        {/* Sliders */}
        <div className="space-y-6">
          {renderSlider(
            currentMood.energy,
            (value) => setCurrentMood({ ...currentMood, energy: value }),
            <Heart className="h-5 w-5 text-pink-500" />,
            "Niveau d'énergie"
          )}

          {renderSlider(
            currentMood.stress,
            (value) => setCurrentMood({ ...currentMood, stress: value }),
            <Brain className="h-5 w-5 text-purple-500" />,
            "Niveau de stress"
          )}

          {renderSlider(
            currentMood.motivation,
            (value) => setCurrentMood({ ...currentMood, motivation: value }),
            <ThumbsUp className="h-5 w-5 text-blue-500" />,
            "Niveau de motivation"
          )}
        </div>

        {/* Note */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Une note pour aujourd'hui ? (optionnel)
          </label>
          <textarea
            value={currentMood.note}
            onChange={(e) => setCurrentMood({ ...currentMood, note: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            rows={3}
            placeholder="Comment s'est passée votre journée ?"
          />
        </div>

        {/* Submit Button */}
        <button className="w-full py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors">
          Enregistrer mon humeur
        </button>
      </div>
    </div>
  );
}