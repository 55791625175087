import React from 'react';
import { useRevisionStore } from '../../hooks/useRevisionStore';
import { useFlashcardStore } from '../../hooks/useFlashcardStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { BarChart2, Clock, Brain, Target } from 'lucide-react';

export default function RevisionProgress() {
  const { revisionSessions } = useRevisionStore();
  const { flashcards } = useFlashcardStore();
  const { subjects } = useSubjectStore();

  const subjectStats = subjects.map(subject => {
    const subjectSessions = revisionSessions.filter(
      session => session.subjectId === subject.id
    );
    const subjectCards = flashcards.filter(
      card => card.subjectId === subject.id
    );
    
    const totalDuration = subjectSessions.reduce(
      (acc, session) => acc + session.duration,
      0
    );
    const completedSessions = subjectSessions.filter(
      session => session.completed
    ).length;

    const totalReviews = subjectCards.reduce(
      (acc, card) => acc + (card.reviewCount || 0),
      0
    );
    const successfulReviews = subjectCards.reduce(
      (acc, card) => acc + (card.successCount || 0),
      0
    );

    const flashcardProgress = totalReviews > 0
      ? (successfulReviews / totalReviews) * 100
      : 0;

    const sessionProgress = subjectSessions.length > 0
      ? (completedSessions / subjectSessions.length) * 100
      : 0;

    // Moyenne pondérée : 60% sessions, 40% flashcards
    const totalProgress = (sessionProgress * 0.6) + (flashcardProgress * 0.4);

    return {
      ...subject,
      totalDuration,
      completedSessions,
      totalSessions: subjectSessions.length,
      progress: totalProgress,
      flashcardProgress,
      sessionProgress,
      totalReviews,
      successfulReviews
    };
  });

  const totalStats = {
    totalDuration: subjectStats.reduce((acc, stat) => acc + stat.totalDuration, 0),
    totalSessions: subjectStats.reduce((acc, stat) => acc + stat.totalSessions, 0),
    completedSessions: subjectStats.reduce((acc, stat) => acc + stat.completedSessions, 0),
    totalReviews: subjectStats.reduce((acc, stat) => acc + stat.totalReviews, 0),
    successfulReviews: subjectStats.reduce((acc, stat) => acc + stat.successfulReviews, 0)
  };

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-6">Statistiques Globales</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <Clock className="h-5 w-5 text-indigo-600" />
              <span className="text-sm text-gray-600">Temps total</span>
            </div>
            <div className="text-2xl font-bold">
              {Math.round(totalStats.totalDuration / 60)} h
            </div>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <Target className="h-5 w-5 text-green-600" />
              <span className="text-sm text-gray-600">Sessions</span>
            </div>
            <div className="text-2xl font-bold">
              {totalStats.completedSessions}/{totalStats.totalSessions}
            </div>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <Brain className="h-5 w-5 text-purple-600" />
              <span className="text-sm text-gray-600">Cartes révisées</span>
            </div>
            <div className="text-2xl font-bold">
              {totalStats.totalReviews}
            </div>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-2 mb-2">
              <BarChart2 className="h-5 w-5 text-blue-600" />
              <span className="text-sm text-gray-600">Taux de réussite</span>
            </div>
            <div className="text-2xl font-bold">
              {totalStats.totalReviews > 0
                ? Math.round((totalStats.successfulReviews / totalStats.totalReviews) * 100)
                : 0}%
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-6">Progression par Matière</h2>
        <div className="space-y-6">
          {subjectStats.map((stat) => (
            <div key={stat.id}>
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center gap-2">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: stat.color }}
                  />
                  <span className="font-medium">{stat.name}</span>
                </div>
                <span className="text-sm text-gray-500">
                  {Math.round(stat.progress)}%
                </span>
              </div>
              <div className="space-y-2">
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div
                    className="h-2 rounded-full transition-all duration-500"
                    style={{
                      width: `${stat.progress}%`,
                      backgroundColor: stat.color
                    }}
                  />
                </div>
                <div className="flex justify-between text-xs text-gray-500">
                  <span>Sessions: {stat.completedSessions}/{stat.totalSessions}</span>
                  <span>Flashcards: {stat.successfulReviews}/{stat.totalReviews}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}