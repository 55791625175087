import { doc, setDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useTaskStore } from '../hooks/useTaskStore';
import { useGradeStore } from '../hooks/useGradeStore';
import { useNotesStore } from '../hooks/useNotesStore';
import { useRevisionStore } from '../hooks/useRevisionStore';
import { useFlashcardStore } from '../hooks/useFlashcardStore';
import { useSubjectStore } from '../hooks/useSubjectStore';

export async function syncUserData(userId: string) {
  if (!userId) return;

  try {
    const userDoc = doc(db, 'users', userId);
    const stores = {
      tasks: useTaskStore.getState().tasks,
      grades: useGradeStore.getState().grades,
      notes: useNotesStore.getState().notes,
      revisions: useRevisionStore.getState().revisionSessions,
      flashcards: useFlashcardStore.getState().flashcards,
      subjects: useSubjectStore.getState().subjects
    };

    await updateDoc(userDoc, {
      ...stores,
      lastSyncedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error syncing user data:', error);
    throw error;
  }
}

export async function loadUserData(userId: string) {
  if (!userId) return;

  try {
    const userDoc = await getDocs(query(collection(db, 'users'), where('id', '==', userId)));
    if (!userDoc.empty) {
      const userData = userDoc.docs[0].data();
      
      // Mettre à jour chaque store avec les données de Firebase
      useTaskStore.setState({ tasks: userData.tasks || [] });
      useGradeStore.setState({ grades: userData.grades || [] });
      useNotesStore.setState({ notes: userData.notes || [] });
      useRevisionStore.setState({ revisionSessions: userData.revisions || [] });
      useFlashcardStore.setState({ flashcards: userData.flashcards || [] });
      useSubjectStore.setState({ subjects: userData.subjects || [] });
    }
  } catch (error) {
    console.error('Error loading user data:', error);
    throw error;
  }
}

// Fonction utilitaire pour synchroniser un store spécifique
export async function syncStore(userId: string, storeName: string, data: any) {
  if (!userId) return;

  try {
    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, {
      [storeName]: data,
      [`${storeName}LastSyncedAt`]: new Date().toISOString()
    });
  } catch (error) {
    console.error(`Error syncing ${storeName}:`, error);
    throw error;
  }
}