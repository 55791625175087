import React, { createContext, useContext, useState } from 'react';

interface Theme {
  colors: {
    primary: string;
    secondary: string;
    accent: string;
  };
  typography: {
    fontFamily: string;
    baseSize: number;
  };
  layout: {
    density: 'compact' | 'comfortable' | 'spacious';
    contentWidth: 'full' | 'contained';
  };
  darkMode: boolean;
}

interface ThemeContextType {
  theme: Theme;
  updateTheme: (newTheme: Theme) => void;
}

const defaultTheme: Theme = {
  colors: {
    primary: '#4F46E5',
    secondary: '#818CF8',
    accent: '#C7D2FE'
  },
  typography: {
    fontFamily: 'font-sans',
    baseSize: 16
  },
  layout: {
    density: 'comfortable',
    contentWidth: 'contained'
  },
  darkMode: false
};

const ThemeContext = createContext<ThemeContextType | null>(null);

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const updateTheme = (newTheme: Theme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}