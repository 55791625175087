import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import GradeManager from '../components/Grading/GradeManager';
import GradeAnalytics from '../components/Grading/GradeAnalytics';
import GradeGoals from '../components/Grading/GradeGoals';
import GradeComparison from '../components/Grading/GradeComparison';
import { Calculator, BarChart2, Target, Users } from 'lucide-react';

export default function GradingPage() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          Notation
        </h1>
      </div>

      <Tabs defaultValue="grades" className="space-y-6">
        <TabsList className="bg-white p-1 rounded-lg shadow-sm">
          <TabsTrigger value="grades" className="flex items-center gap-2">
            <Calculator className="h-4 w-4" />
            Notes
          </TabsTrigger>
          <TabsTrigger value="analytics" className="flex items-center gap-2">
            <BarChart2 className="h-4 w-4" />
            Analyses
          </TabsTrigger>
          <TabsTrigger value="goals" className="flex items-center gap-2">
            <Target className="h-4 w-4" />
            Objectifs
          </TabsTrigger>
          <TabsTrigger value="comparison" className="flex items-center gap-2">
            <Users className="h-4 w-4" />
            Comparaison
          </TabsTrigger>
        </TabsList>

        <TabsContent value="grades">
          <GradeManager />
        </TabsContent>

        <TabsContent value="analytics">
          <GradeAnalytics />
        </TabsContent>

        <TabsContent value="goals">
          <GradeGoals />
        </TabsContent>

        <TabsContent value="comparison">
          <GradeComparison />
        </TabsContent>
      </Tabs>
    </div>
  );
}