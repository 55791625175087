import React, { useState } from 'react';
import { useGradeStore } from '../../hooks/useGradeStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { Plus, Filter, SortAsc, Edit2, Trash2 } from 'lucide-react';
import GradeForm from './GradeForm';

export default function GradeManager() {
  const { grades, addGrade, updateGrade, deleteGrade } = useGradeStore();
  const { subjects } = useSubjectStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [sortBy, setSortBy] = useState<'date' | 'value'>('date');
  const [editingGrade, setEditingGrade] = useState<any>(null);

  const filteredGrades = grades
    .filter(grade => !selectedSubject || grade.subjectId === selectedSubject)
    .filter(grade => !selectedType || grade.type === selectedType)
    .sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return b.value - a.value;
    });

  const calculateAverage = (subjectId?: string) => {
    const relevantGrades = subjectId
      ? grades.filter(g => g.subjectId === subjectId)
      : grades;

    if (relevantGrades.length === 0) return 0;

    const totalWeightedGrade = relevantGrades.reduce(
      (acc, grade) => acc + (grade.value * grade.coefficient),
      0
    );
    const totalCoefficient = relevantGrades.reduce(
      (acc, grade) => acc + grade.coefficient,
      0
    );

    return totalWeightedGrade / totalCoefficient;
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-4">
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="px-3 py-2 border rounded-lg"
            >
              <option value="">Toutes les matières</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>

            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className="px-3 py-2 border rounded-lg"
            >
              <option value="">Tous les types</option>
              <option value="homework">Devoir</option>
              <option value="test">Contrôle</option>
              <option value="exam">Examen</option>
              <option value="project">Projet</option>
            </select>

            <button
              onClick={() => setSortBy(sortBy === 'date' ? 'value' : 'date')}
              className="flex items-center gap-2 px-3 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              <SortAsc className="h-4 w-4" />
              Trier par {sortBy === 'date' ? 'note' : 'date'}
            </button>
          </div>

          <button
            onClick={() => {
              setEditingGrade(null);
              setIsModalOpen(true);
            }}
            className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-4 w-4" />
            Nouvelle note
          </button>
        </div>

        <div className="space-y-4">
          {filteredGrades.map((grade) => {
            const subject = subjects.find(s => s.id === grade.subjectId);
            return (
              <div
                key={grade.id}
                className="p-4 border rounded-lg hover:bg-gray-50"
                style={{ borderLeft: `4px solid ${subject?.color || '#4F46E5'}` }}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-2xl font-bold">{grade.value}/20</span>
                      <span className="text-sm text-gray-500">
                        (coef. {grade.coefficient})
                      </span>
                    </div>
                    <div className="flex items-center gap-4 mt-1 text-sm text-gray-600">
                      {subject && (
                        <span className="flex items-center gap-1">
                          <div
                            className="w-2 h-2 rounded-full"
                            style={{ backgroundColor: subject.color }}
                          />
                          {subject.name}
                        </span>
                      )}
                      <span>{new Date(grade.date).toLocaleDateString()}</span>
                      <span className="capitalize">{grade.type}</span>
                    </div>
                    {grade.comment && (
                      <p className="mt-2 text-sm text-gray-600">{grade.comment}</p>
                    )}
                  </div>

                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => {
                        setEditingGrade(grade);
                        setIsModalOpen(true);
                      }}
                      className="p-1 text-gray-400 hover:text-indigo-600 rounded"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => deleteGrade(grade.id)}
                      className="p-1 text-gray-400 hover:text-red-500 rounded"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Moyenne générale</h2>
          <div className="text-4xl font-bold text-indigo-600">
            {calculateAverage().toFixed(2)}/20
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Moyennes par matière</h2>
          <div className="space-y-4">
            {subjects.map((subject) => {
              const average = calculateAverage(subject.id);
              return (
                <div key={subject.id} className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: subject.color }}
                    />
                    <span>{subject.name}</span>
                  </div>
                  <span className="font-semibold">{average.toFixed(2)}/20</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <GradeForm
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEditingGrade(null);
          }}
          onSubmit={(grade) => {
            if (editingGrade) {
              updateGrade(editingGrade.id, grade);
            } else {
              addGrade(grade);
            }
            setIsModalOpen(false);
            setEditingGrade(null);
          }}
          initialData={editingGrade}
        />
      )}
    </div>
  );
}