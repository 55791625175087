import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSubjectStore } from './useSubjectStore';
import { useTaskStore } from './useTaskStore';
import { useGradeStore } from './useGradeStore';
import { useRevisionStore } from './useRevisionStore';
import { useRewardsStore } from './useRewardsStore';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { loadUserData } from '../services/firebaseSync';

export const useInitialization = () => {
  const { currentUser } = useAuth();
  const { subjects, addSubject } = useSubjectStore();
  const resetTasks = useTaskStore(state => state.reset);
  const resetGrades = useGradeStore(state => state.reset);
  const resetRevisions = useRevisionStore(state => state.reset);
  const resetRewards = useRewardsStore(state => state.reset);

  const initializeDefaultSubjects = async () => {
    const defaultSubjects = [
      { name: 'Mathématiques', color: '#4F46E5', description: 'Algèbre, géométrie, analyse...' },
      { name: 'Français', color: '#EF4444', description: 'Littérature, grammaire, expression écrite...' },
      { name: 'Histoire-Géographie', color: '#F59E0B', description: 'Histoire, géographie, éducation civique...' },
      { name: 'Sciences', color: '#10B981', description: 'Physique-chimie, SVT...' },
      { name: 'Langues', color: '#8B5CF6', description: 'Anglais, espagnol, allemand...' }
    ];

    for (const subject of defaultSubjects) {
      addSubject(subject);
    }
  };

  const resetAllStores = () => {
    resetTasks();
    resetGrades();
    resetRevisions();
    resetRewards();
  };

  useEffect(() => {
    if (!currentUser) return;

    const initializeUser = async () => {
      try {
        // Vérifier si l'utilisateur existe déjà
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        
        if (!userDoc.exists()) {
          // Nouvel utilisateur : initialiser avec les valeurs par défaut
          resetAllStores();
          await initializeDefaultSubjects();
          
          await setDoc(doc(db, 'users', currentUser.uid), {
            initialized: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            subjects: subjects,
            stats: {
              totalStudyTime: 0,
              totalTasksCompleted: 0,
              averageGrade: 0,
              streakDays: 0
            },
            rewards: {
              points: 0,
              level: 1,
              badges: [],
              achievements: []
            }
          });
        } else {
          // Utilisateur existant : charger ses données
          await loadUserData(currentUser.uid);
        }
      } catch (error) {
        console.error('Error initializing user:', error);
      }
    };

    initializeUser();
  }, [currentUser]);

  return {
    initializeDefaultSubjects,
    resetAllStores
  };
};