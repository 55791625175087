import React, { useState, useRef } from 'react';
import { Camera, Upload, Image as ImageIcon, Loader, Check, AlertCircle } from 'lucide-react';
import { createWorker } from 'tesseract.js';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';

export default function DocumentScanner() {
  const { currentUser } = useAuth();
  const [scanning, setScanning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [recognizedText, setRecognizedText] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: 'environment' }
      });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      toast.error('Impossible d\'accéder à la caméra');
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  };

  const captureImage = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0);
        const imageUrl = canvas.toDataURL('image/jpeg');
        setPreviewUrl(imageUrl);
        stopCamera();
        processImage(imageUrl);
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        setPreviewUrl(imageUrl);
        processImage(imageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const processImage = async (imageUrl: string) => {
    setScanning(true);
    setProgress(0);

    try {
      const worker = await createWorker({
        logger: m => {
          if (m.status === 'recognizing text') {
            setProgress(Math.round(m.progress * 100));
          }
        },
      });

      await worker.loadLanguage('fra');
      await worker.initialize('fra');
      const { data: { text } } = await worker.recognize(imageUrl);
      setRecognizedText(text);
      await worker.terminate();
      
      toast.success('Texte extrait avec succès !');
    } catch (error) {
      toast.error('Erreur lors de l\'extraction du texte');
    } finally {
      setScanning(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold">Scanner de Documents</h2>
        <div className="flex gap-2">
          <button
            onClick={() => fileInputRef.current?.click()}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            <Upload className="h-4 w-4" />
            Importer
          </button>
          <button
            onClick={startCamera}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
          >
            <Camera className="h-4 w-4" />
            Scanner
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept="image/*"
            className="hidden"
          />
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <div>
          {previewUrl ? (
            <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100">
              <img
                src={previewUrl}
                alt="Preview"
                className="w-full h-full object-contain"
              />
            </div>
          ) : (
            <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100">
              <video
                ref={videoRef}
                autoPlay
                playsInline
                className="w-full h-full object-cover"
              />
              {streamRef.current && (
                <button
                  onClick={captureImage}
                  className="absolute bottom-4 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-white rounded-full shadow-lg flex items-center gap-2"
                >
                  <Camera className="h-5 w-5" />
                  Capturer
                </button>
              )}
            </div>
          )}
        </div>

        <div>
          {scanning ? (
            <div className="h-full flex flex-col items-center justify-center">
              <Loader className="h-8 w-8 text-indigo-600 animate-spin mb-4" />
              <p className="text-gray-600 mb-2">Extraction du texte...</p>
              <div className="w-full max-w-xs bg-gray-200 rounded-full h-2">
                <div
                  className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          ) : recognizedText ? (
            <div className="h-full flex flex-col">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium">Texte extrait</h3>
                <button className="text-indigo-600 hover:text-indigo-700 text-sm font-medium">
                  Copier
                </button>
              </div>
              <textarea
                value={recognizedText}
                onChange={(e) => setRecognizedText(e.target.value)}
                className="flex-1 p-4 border rounded-lg resize-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Le texte extrait apparaîtra ici..."
              />
            </div>
          ) : (
            <div className="h-full flex flex-col items-center justify-center text-gray-500">
              <ImageIcon className="h-12 w-12 mb-4" />
              <p>Scannez ou importez une image pour extraire le texte</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}