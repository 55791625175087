import React from 'react';
import RewardsOverview from '../components/gamification/RewardsOverview';
import BadgeSystem from '../components/gamification/BadgeSystem';
import DailyChallenges from '../components/gamification/DailyChallenges';
import AchievementTimeline from '../components/gamification/AchievementTimeline';

export default function GamificationPage() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-4xl font-bold text-gray-900 mb-12">
        Récompenses et Progression
      </h1>

      <div className="grid gap-8">
        {/* Vue d'ensemble des récompenses */}
        <RewardsOverview />

        {/* Système de badges et défis quotidiens */}
        <div className="grid gap-8 lg:grid-cols-2">
          <BadgeSystem />
          <DailyChallenges />
        </div>

        {/* Timeline des réalisations */}
        <AchievementTimeline />
      </div>
    </div>
  );
}