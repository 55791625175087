import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { GraduationCap } from 'lucide-react';

export default function Footer() {
  const { currentUser } = useAuth();

  const sections = [
    {
      title: "Fonctionnalités",
      links: [
        { name: "Calendrier", path: "/studyflow/taches" },
        { name: "Révisions", path: "/studyflow/revisions" },
        { name: "Notes de cours", path: "/studyflow/notes-de-cours" },
        { name: "Notation", path: "/studyflow/notation" }
      ]
    },
    {
      title: "Outils",
      links: [
        { name: "Analyses", path: "/studyflow/analyses" },
        { name: "Bien-être", path: "/studyflow/bien-etre" },
        { name: "Récompenses", path: "/studyflow/recompenses" },
        { name: "Scanner", path: "/studyflow/scanner" }
      ]
    },
    {
      title: "Compte",
      links: [
        { name: "Connexion", path: "/login" },
        { name: "Inscription", path: "/signup" }
      ]
    }
  ];

  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo et description */}
          <div className="col-span-1">
            <Link to="/" className="flex items-center gap-2">
              <GraduationCap className="h-8 w-8 text-indigo-600" />
              <span className="text-xl font-bold text-gray-900">StudyFlow</span>
            </Link>
            <p className="mt-4 text-sm text-gray-600">
              Votre plateforme tout-en-un pour améliorer vos résultats scolaires et organiser vos études efficacement.
            </p>
          </div>

          {/* Sections de liens */}
          {sections.map((section) => (
            <div key={section.title}>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                {section.title}
              </h3>
              <ul className="mt-4 space-y-4">
                {section.links.map((link) => (
                  <li key={link.name}>
                    <Link
                      to={link.path}
                      className="text-base text-gray-600 hover:text-indigo-600 transition-colors"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Boutons d'action */}
        {!currentUser && (
          <div className="mt-12 flex flex-col sm:flex-row justify-center gap-4">
            <Link
              to="/signup"
              className="inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-xl text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
            >
              Commencer gratuitement
            </Link>
            <Link
              to="/login"
              className="inline-flex justify-center items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-xl text-indigo-600 bg-white hover:bg-gray-50 transition-colors"
            >
              Se connecter
            </Link>
          </div>
        )}

        {/* Copyright */}
        <div className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-center text-sm text-gray-500">
            StudyFlow a été réalisé par Ydz
          </p>
        </div>
      </div>
    </footer>
  );
}