import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TimerState {
  timeLeft: number;
  isRunning: boolean;
  isBreak: boolean;
  cycles: number;
  showFloatingTimer: boolean;
  lastUpdate: number;
  setTimeLeft: (time: number) => void;
  setIsRunning: (running: boolean) => void;
  setIsBreak: (isBreak: boolean) => void;
  setCycles: (cycles: number) => void;
  setShowFloatingTimer: (show: boolean) => void;
  updateLastUpdate: () => void;
  reset: () => void;
}

export const useTimerStore = create<TimerState>()(
  persist(
    (set) => ({
      timeLeft: 25 * 60,
      isRunning: false,
      isBreak: false,
      cycles: 0,
      showFloatingTimer: false,
      lastUpdate: Date.now(),
      
      setTimeLeft: (time) => set({ timeLeft: time }),
      setIsRunning: (running) => set({ isRunning: running }),
      setIsBreak: (isBreak) => set({ isBreak }),
      setCycles: (cycles) => set({ cycles }),
      setShowFloatingTimer: (show) => set({ showFloatingTimer: show }),
      updateLastUpdate: () => set({ lastUpdate: Date.now() }),
      reset: () => set({
        timeLeft: 25 * 60,
        isRunning: false,
        isBreak: false,
        cycles: 0,
        showFloatingTimer: false,
        lastUpdate: Date.now()
      })
    }),
    {
      name: 'timer-store'
    }
  )
);