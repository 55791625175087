import React, { useState } from 'react';
import { Upload, File, AlertCircle, Check } from 'lucide-react';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { useNotesStore } from '../../hooks/useNotesStore';

export default function DocumentImport() {
  const { subjects } = useSubjectStore();
  const { addNote } = useNotesStore();
  const [selectedSubject, setSelectedSubject] = useState('');
  const [importStatus, setImportStatus] = useState<'idle' | 'processing' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !selectedSubject) return;

    setImportStatus('processing');
    setErrorMessage('');

    try {
      // Simuler le traitement du fichier
      await new Promise(resolve => setTimeout(resolve, 2000));

      // TODO: Implémenter la logique d'importation réelle
      // Pour l'instant, on simule l'ajout d'une note
      addNote({
        title: file.name.replace(/\.[^/.]+$/, ""),
        content: "Contenu importé de " + file.name,
        subjectId: selectedSubject,
        tags: [],
        importance: 'medium',
        urgency: 'medium'
      });

      setImportStatus('success');
    } catch (error) {
      setImportStatus('error');
      setErrorMessage('Erreur lors de l\'importation du fichier');
    }
  };

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-6">Importer un document</h2>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Matière
            </label>
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
            >
              <option value="">Sélectionner une matière</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
          </div>

          <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
            <input
              type="file"
              id="file-upload"
              className="hidden"
              onChange={handleFileUpload}
              accept=".doc,.docx,.pdf,.txt"
              disabled={!selectedSubject || importStatus === 'processing'}
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer block text-center"
            >
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <span className="mt-2 block text-sm font-medium text-gray-900">
                Cliquez pour sélectionner un fichier
              </span>
              <span className="mt-1 block text-sm text-gray-500">
                DOC, DOCX, PDF, TXT jusqu'à 10MB
              </span>
            </label>
          </div>

          {importStatus === 'processing' && (
            <div className="flex items-center justify-center text-indigo-600">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-current"></div>
              <span className="ml-2">Importation en cours...</span>
            </div>
          )}

          {importStatus === 'success' && (
            <div className="flex items-center justify-center text-green-600">
              <Check className="h-6 w-6" />
              <span className="ml-2">Importation réussie</span>
            </div>
          )}

          {importStatus === 'error' && (
            <div className="flex items-center justify-center text-red-600">
              <AlertCircle className="h-6 w-6" />
              <span className="ml-2">{errorMessage}</span>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Formats supportés</h3>
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <File className="h-8 w-8 text-blue-500" />
              <div>
                <p className="font-medium">Documents Word (.doc, .docx)</p>
                <p className="text-sm text-gray-500">
                  Conserve la mise en forme et les styles
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <File className="h-8 w-8 text-red-500" />
              <div>
                <p className="font-medium">PDF (.pdf)</p>
                <p className="text-sm text-gray-500">
                  Extraction du texte et conservation de la structure
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <File className="h-8 w-8 text-gray-500" />
              <div>
                <p className="font-medium">Texte brut (.txt)</p>
                <p className="text-sm text-gray-500">
                  Import simple sans mise en forme
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Conseils d'importation</h3>
          <ul className="space-y-2 text-sm text-gray-600">
            <li>• Vérifiez que le document est correctement formaté avant l'import</li>
            <li>• Les images seront conservées pour les documents Word et PDF</li>
            <li>• La taille maximale par fichier est de 10MB</li>
            <li>• Les documents sont automatiquement sauvegardés après l'import</li>
          </ul>
        </div>
      </div>
    </div>
  );
}