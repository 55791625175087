import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Note {
  id: string;
  title: string;
  content: string;
  subjectId: string;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  importance: 'low' | 'medium' | 'high';
  urgency: 'low' | 'medium' | 'high';
  viewCount: number;
  timeSpent: number;
  version: number;
  versions: {
    version: number;
    content: string;
    updatedAt: string;
  }[];
}

interface NotesStore {
  notes: Note[];
  addNote: (note: Omit<Note, 'id' | 'createdAt' | 'updatedAt' | 'viewCount' | 'timeSpent' | 'version' | 'versions'>) => void;
  updateNote: (id: string, updates: Partial<Note>) => void;
  deleteNote: (id: string) => void;
  incrementViewCount: (id: string) => void;
  updateTimeSpent: (id: string, seconds: number) => void;
  createVersion: (id: string) => void;
  restoreVersion: (id: string, version: number) => void;
}

export const useNotesStore = create<NotesStore>()(
  persist(
    (set) => ({
      notes: [],
      
      addNote: (note) => set((state) => ({
        notes: [...state.notes, {
          ...note,
          id: Date.now().toString(),
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          viewCount: 0,
          timeSpent: 0,
          version: 1,
          versions: [{
            version: 1,
            content: note.content,
            updatedAt: new Date().toISOString()
          }]
        }]
      })),
      
      updateNote: (id, updates) => set((state) => ({
        notes: state.notes.map(note =>
          note.id === id
            ? {
                ...note,
                ...updates,
                updatedAt: new Date().toISOString()
              }
            : note
        )
      })),
      
      deleteNote: (id) => set((state) => ({
        notes: state.notes.filter(note => note.id !== id)
      })),
      
      incrementViewCount: (id) => set((state) => ({
        notes: state.notes.map(note =>
          note.id === id
            ? { ...note, viewCount: note.viewCount + 1 }
            : note
        )
      })),
      
      updateTimeSpent: (id, seconds) => set((state) => ({
        notes: state.notes.map(note =>
          note.id === id
            ? { ...note, timeSpent: note.timeSpent + seconds }
            : note
        )
      })),
      
      createVersion: (id) => set((state) => ({
        notes: state.notes.map(note =>
          note.id === id
            ? {
                ...note,
                version: note.version + 1,
                versions: [
                  ...note.versions,
                  {
                    version: note.version + 1,
                    content: note.content,
                    updatedAt: new Date().toISOString()
                  }
                ]
              }
            : note
        )
      })),
      
      restoreVersion: (id, version) => set((state) => ({
        notes: state.notes.map(note => {
          if (note.id !== id) return note;
          const versionData = note.versions.find(v => v.version === version);
          if (!versionData) return note;
          return {
            ...note,
            content: versionData.content,
            updatedAt: new Date().toISOString()
          };
        })
      }))
    }),
    {
      name: 'notes-store'
    }
  )
);