import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import { X } from 'lucide-react';

// Créer un type pour l'objet window étendu
declare global {
  interface Window {
    openAuthModal: () => void;
    closeAuthModal: () => void;
  }
}

export default function AuthModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [mode, setMode] = React.useState<'login' | 'signup'>('signup');
  const { currentUser } = useAuth();

  // Exposer les fonctions de contrôle du modal globalement
  React.useEffect(() => {
    window.openAuthModal = () => setIsOpen(true);
    window.closeAuthModal = () => setIsOpen(false);

    return () => {
      // Cleanup
      window.openAuthModal = () => {};
      window.closeAuthModal = () => {};
    };
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  if (currentUser) return null;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center mb-4">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {mode === 'signup' ? 'Créer un compte StudyFlow' : 'Se connecter à StudyFlow'}
                  </Dialog.Title>
                  <button
                    onClick={closeModal}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>

                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Vous avez besoin d'un compte StudyFlow pour utiliser cette page. C'est totalement gratuit !
                  </p>
                </div>

                <div className="mt-4">
                  {mode === 'signup' ? (
                    <SignupForm onSuccess={closeModal} />
                  ) : (
                    <LoginForm onSuccess={closeModal} />
                  )}
                </div>

                <div className="mt-4 text-center">
                  <button
                    type="button"
                    className="text-sm text-indigo-600 hover:text-indigo-500"
                    onClick={() => setMode(mode === 'signup' ? 'login' : 'signup')}
                  >
                    {mode === 'signup' 
                      ? 'Déjà un compte ? Se connecter' 
                      : 'Pas encore de compte ? S\'inscrire'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}