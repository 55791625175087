import React from 'react';
import { useNotesStore } from '../../hooks/useNotesStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { FileText, Clock, Tag, BarChart2 } from 'lucide-react';

interface NotesStatsProps {
  detailed?: boolean;
}

export default function NotesStats({ detailed = false }: NotesStatsProps) {
  const { notes } = useNotesStore();
  const { subjects } = useSubjectStore();

  const totalNotes = notes.length;
  const totalTimeSpent = notes.reduce((acc, note) => acc + note.timeSpent, 0);
  const uniqueTags = Array.from(new Set(notes.flatMap(note => note.tags))).length;

  const subjectStats = subjects.map(subject => {
    const subjectNotes = notes.filter(note => note.subjectId === subject.id);
    return {
      ...subject,
      noteCount: subjectNotes.length,
      timeSpent: subjectNotes.reduce((acc, note) => acc + note.timeSpent, 0),
      avgTimePerNote: subjectNotes.length > 0
        ? subjectNotes.reduce((acc, note) => acc + note.timeSpent, 0) / subjectNotes.length
        : 0
    };
  });

  if (!detailed) {
    return (
      <div className="flex gap-4">
        <div className="bg-white p-4 rounded-lg shadow-sm flex items-center gap-4">
          <div className="p-2 rounded-full bg-indigo-100">
            <FileText className="h-5 w-5 text-indigo-600" />
          </div>
          <div>
            <p className="text-sm text-gray-600">Total des notes</p>
            <p className="text-2xl font-semibold">{totalNotes}</p>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm flex items-center gap-4">
          <div className="p-2 rounded-full bg-green-100">
            <Clock className="h-5 w-5 text-green-600" />
          </div>
          <div>
            <p className="text-sm text-gray-600">Temps total</p>
            <p className="text-2xl font-semibold">{Math.round(totalTimeSpent / 60)}h</p>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm flex items-center gap-4">
          <div className="p-2 rounded-full bg-yellow-100">
            <Tag className="h-5 w-5 text-yellow-600" />
          </div>
          <div>
            <p className="text-sm text-gray-600">Tags uniques</p>
            <p className="text-2xl font-semibold">{uniqueTags}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold mb-6">Statistiques par matière</h2>
        <div className="space-y-6">
          {subjectStats.map((stat) => (
            <div key={stat.id}>
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center gap-2">
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{ backgroundColor: stat.color }}
                  />
                  <span className="font-medium">{stat.name}</span>
                </div>
                <span className="text-sm text-gray-500">
                  {stat.noteCount} note{stat.noteCount !== 1 ? 's' : ''}
                </span>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="p-3 bg-gray-50 rounded-lg">
                  <div className="text-sm text-gray-500">Temps total</div>
                  <div className="text-lg font-semibold">
                    {Math.round(stat.timeSpent / 60)}h
                  </div>
                </div>
                <div className="p-3 bg-gray-50 rounded-lg">
                  <div className="text-sm text-gray-500">Temps moyen/note</div>
                  <div className="text-lg font-semibold">
                    {Math.round(stat.avgTimePerNote)} min
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Activité récente</h3>
          <div className="space-y-4">
            {notes
              .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
              .slice(0, 5)
              .map((note) => {
                const subject = subjects.find(s => s.id === note.subjectId);
                return (
                  <div key={note.id} className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{ backgroundColor: subject?.color }}
                      />
                      <span className="font-medium">{note.title}</span>
                    </div>
                    <span className="text-sm text-gray-500">
                      {new Date(note.updatedAt).toLocaleDateString()}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-4">Tags les plus utilisés</h3>
          <div className="flex flex-wrap gap-2">
            {Array.from(
              notes.flatMap(note => note.tags)
                .reduce((acc, tag) => acc.set(tag, (acc.get(tag) || 0) + 1), new Map())
            )
              .sort(([, a], [, b]) => b - a)
              .slice(0, 10)
              .map(([tag, count]) => (
                <div
                  key={tag}
                  className="px-3 py-1 bg-gray-100 rounded-full text-sm flex items-center gap-2"
                >
                  <span>{tag}</span>
                  <span className="text-gray-500">{count}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}