import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Plus, Calendar, Clock, Bell } from 'lucide-react';
import TaskForm from './TaskForm';
import { useTaskStore } from '../../stores/taskStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { useSyncedData } from '../../hooks/useFirebaseSync';

export default function TaskCalendar() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [view, setView] = useState<'dayGridMonth' | 'timeGridWeek'>('dayGridMonth');
  const taskStore = useTaskStore();
  const { subjects } = useSubjectStore();

  // Synchroniser les données avec Firebase
  useSyncedData('tasks', taskStore);

  const events = taskStore.data.map(task => {
    const subject = subjects.find(s => s.name === task.subject);
    return {
      id: task.id,
      title: task.title,
      start: `${task.date}${task.time ? `T${task.time}` : ''}`,
      backgroundColor: subject?.color || task.color || '#4F46E5',
      borderColor: subject?.color || task.color || '#4F46E5',
      classNames: ['task-event'],
      extendedProps: {
        ...task
      },
      display: 'block',
      textColor: '#ffffff'
    };
  });

  const handleDateClick = (arg: { date: Date; allDay: boolean }) => {
    setSelectedDate(arg.date);
    setSelectedTask(null);
    setIsModalOpen(true);
  };

  const handleEventClick = (arg: any) => {
    setSelectedTask(arg.event.extendedProps);
    setIsModalOpen(true);
  };

  const handleViewChange = () => {
    setView(view === 'dayGridMonth' ? 'timeGridWeek' : 'dayGridMonth');
  };

  const handleTaskSubmit = (taskData: any) => {
    if (selectedTask) {
      taskStore.update(selectedTask.id, taskData);
    } else {
      taskStore.add(taskData);
    }
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <button
            onClick={handleViewChange}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            {view === 'dayGridMonth' ? 'Vue Semaine' : 'Vue Mois'}
          </button>
        </div>

        <button
          onClick={() => {
            setSelectedDate(new Date());
            setSelectedTask(null);
            setIsModalOpen(true);
          }}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4" />
          Nouvelle tâche
        </button>
      </div>

      <style>
        {`
          .fc-event {
            padding: 4px 8px;
            margin: 2px 0;
            border-radius: 6px;
            border: none !important;
          }
          .fc-event-title {
            padding: 2px 4px;
            font-weight: 500;
          }
          .fc-day-today {
            background-color: #F3F4F6 !important;
          }
          .fc-event:hover {
            filter: brightness(90%);
          }
        `}
      </style>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={view}
        locale={fr}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: ''
        }}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        events={events}
        height="auto"
        eventContent={(arg) => (
          <div className="flex items-center gap-1 w-full">
            <span className="flex-1 truncate">{arg.event.title}</span>
            {arg.event.extendedProps.notifications?.length > 0 && (
              <Bell className="h-3 w-3 flex-shrink-0" />
            )}
          </div>
        )}
      />

      {isModalOpen && (
        <TaskForm
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedTask(null);
          }}
          selectedDate={selectedDate}
          onSubmit={handleTaskSubmit}
          initialData={selectedTask}
        />
      )}
    </div>
  );
}