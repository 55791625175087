import React from 'react';
import PageHead from '../components/layout/PageHead';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import RevisionPlanner from '../components/Revision/RevisionPlanner';
import FlashcardManager from '../components/Revision/FlashcardManager';
import RevisionProgress from '../components/Revision/RevisionProgress';
import RevisionTimer from '../components/Revision/RevisionTimer';
import FloatingTimer from '../components/Revision/FloatingTimer';
import { BookOpen, Brain, Clock, BarChart2 } from 'lucide-react';

export default function RevisionPage() {
  const [showFloatingTimer, setShowFloatingTimer] = React.useState(false);
  const [timerData, setTimerData] = React.useState({
    timeLeft: 0,
    isBreak: false,
    cycles: 0
  });

  return (
    <>
      <PageHead
        title="Outils de Révision et Fiches de Cours"
        description="Maximisez votre temps d'étude avec les outils de révision personnalisés StudyFlow. Créez des fiches de révision, suivez votre progression et améliorez vos résultats."
        path="/studyflow/revisions"
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Espace de Révision
          </h1>
        </div>

        {showFloatingTimer && (
          <FloatingTimer
            timerData={timerData}
            onClose={() => setShowFloatingTimer(false)}
          />
        )}

        <Tabs defaultValue="planner" className="space-y-6">
          <TabsList className="bg-white p-1 rounded-lg shadow-sm">
            <TabsTrigger value="planner" className="flex items-center gap-2">
              <BookOpen className="h-4 w-4" />
              Planning de révisions
            </TabsTrigger>
            <TabsTrigger value="flashcards" className="flex items-center gap-2">
              <Brain className="h-4 w-4" />
              Fiches de révision
            </TabsTrigger>
            <TabsTrigger value="progress" className="flex items-center gap-2">
              <BarChart2 className="h-4 w-4" />
              Progression
            </TabsTrigger>
            <TabsTrigger value="timer" className="flex items-center gap-2">
              <Clock className="h-4 w-4" />
              Timer
            </TabsTrigger>
          </TabsList>

          <TabsContent value="planner" className="mt-6">
            <RevisionPlanner />
          </TabsContent>

          <TabsContent value="flashcards" className="mt-6">
            <FlashcardManager />
          </TabsContent>

          <TabsContent value="progress" className="mt-6">
            <RevisionProgress />
          </TabsContent>

          <TabsContent value="timer" className="mt-6">
            <RevisionTimer
              onTimerStart={() => setShowFloatingTimer(true)}
              onTimerUpdate={setTimerData}
            />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}