import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Plus, Calendar, Clock } from 'lucide-react';
import TaskForm from './TaskForm';
import { useTaskStore } from '../../hooks/useTaskStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';

export default function WeeklyBoard() {
  const { tasks, updateTask } = useTaskStore();
  const { subjects } = useSubjectStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);

  const columns = {
    todo: {
      id: 'todo',
      title: 'À faire',
      tasks: tasks.filter(t => t.status === 'todo')
    },
    'in-progress': {
      id: 'in-progress',
      title: 'En cours',
      tasks: tasks.filter(t => t.status === 'in-progress')
    },
    done: {
      id: 'done',
      title: 'Terminé',
      tasks: tasks.filter(t => t.status === 'done')
    }
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const task = columns[source.droppableId as keyof typeof columns].tasks[source.index];
    updateTask(task.id, { status: destination.droppableId });
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-semibold">Travail de la semaine</h2>
        <button
          onClick={() => {
            setSelectedColumn('todo');
            setIsModalOpen(true);
          }}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4" />
          Nouvelle tâche
        </button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {Object.values(columns).map(column => (
            <div key={column.id} className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-gray-900">{column.title}</h3>
                <span className="text-sm text-gray-500">
                  {column.tasks.length} tâche{column.tasks.length !== 1 ? 's' : ''}
                </span>
              </div>

              <Droppable droppableId={column.id}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="space-y-3 min-h-[200px]"
                  >
                    {column.tasks.map((task, index) => {
                      const subject = subjects.find(s => s.name === task.subject);
                      return (
                        <Draggable
                          key={task.id}
                          draggableId={task.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="bg-white p-4 rounded-lg shadow-sm"
                              style={{
                                ...provided.draggableProps.style,
                                borderLeft: `4px solid ${subject?.color || task.color || '#4F46E5'}`
                              }}
                            >
                              <h4 className="font-medium text-gray-900 mb-2">
                                {task.title}
                              </h4>
                              {task.description && (
                                <p className="text-sm text-gray-600 mb-3">
                                  {task.description}
                                </p>
                              )}
                              <div className="flex items-center gap-4 text-sm text-gray-500">
                                {task.date && (
                                  <div className="flex items-center gap-1">
                                    <Calendar className="h-4 w-4" />
                                    {task.date}
                                  </div>
                                )}
                                {task.subject && (
                                  <div className="flex items-center gap-1">
                                    <div
                                      className="w-3 h-3 rounded-full"
                                      style={{ backgroundColor: subject?.color }}
                                    />
                                    {task.subject}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>

      {isModalOpen && (
        <TaskForm
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedDate={null}
          onSubmit={(task) => {
            updateTask(task.id, { ...task, status: selectedColumn || 'todo' });
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
}