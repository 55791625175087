import React, { useState } from 'react';
import { useTaskStore } from '../../hooks/useTaskStore';
import { useSubjectStore } from '../../hooks/useSubjectStore';
import { 
  CheckCircle2, Circle, Clock, Flag, Trash2, Bell, 
  BellOff, Filter, SortAsc, Tag, Paperclip 
} from 'lucide-react';

export default function TaskList() {
  const { tasks, updateTask, deleteTask } = useTaskStore();
  const { subjects } = useSubjectStore();
  const [filterSubject, setFilterSubject] = useState<string | null>(null);
  const [filterPriority, setFilterPriority] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<'date' | 'priority'>('date');

  const filteredTasks = tasks
    .filter(task => !filterSubject || task.subject === filterSubject)
    .filter(task => !filterPriority || task.priority === filterPriority)
    .sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      }
      const priorityValues = { high: 3, medium: 2, low: 1 };
      return priorityValues[b.priority] - priorityValues[a.priority];
    });

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-4 border-b flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="relative">
            <select
              onChange={(e) => setFilterSubject(e.target.value || null)}
              className="pl-8 pr-4 py-2 border rounded-lg appearance-none"
            >
              <option value="">Toutes les matières</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.name}>
                  {subject.name}
                </option>
              ))}
            </select>
            <Tag className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
          </div>

          <div className="relative">
            <select
              onChange={(e) => setFilterPriority(e.target.value || null)}
              className="pl-8 pr-4 py-2 border rounded-lg appearance-none"
            >
              <option value="">Toutes les priorités</option>
              <option value="high">Haute</option>
              <option value="medium">Moyenne</option>
              <option value="low">Basse</option>
            </select>
            <Flag className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
          </div>
        </div>

        <button
          onClick={() => setSortBy(sortBy === 'date' ? 'priority' : 'date')}
          className="flex items-center gap-2 px-3 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
        >
          <SortAsc className="h-4 w-4" />
          Trier par {sortBy === 'date' ? 'priorité' : 'date'}
        </button>
      </div>

      <div className="divide-y">
        {filteredTasks.map((task) => {
          const subject = subjects.find(s => s.name === task.subject);
          return (
            <div
              key={task.id}
              className="p-4 hover:bg-gray-50 transition flex items-start gap-4"
            >
              <button
                onClick={() => updateTask(task.id, { 
                  status: task.status === 'done' ? 'todo' : 'done' 
                })}
                className="mt-1"
              >
                {task.status === 'done' ? (
                  <CheckCircle2 className="h-5 w-5 text-green-500" />
                ) : (
                  <Circle className="h-5 w-5 text-gray-400" />
                )}
              </button>

              <div className="flex-1">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className={`font-medium ${
                      task.status === 'done' ? 'line-through text-gray-500' : 'text-gray-900'
                    }`}>
                      {task.title}
                    </h3>
                    <p className="text-sm text-gray-600 mt-1">{task.description}</p>
                  </div>

                  <div className="flex items-center gap-2">
                    {task.attachments?.length > 0 && (
                      <div className="text-gray-400">
                        <Paperclip className="h-4 w-4" />
                      </div>
                    )}
                    <div className={`px-2 py-1 rounded text-xs font-medium ${
                      task.priority === 'high' ? 'bg-red-100 text-red-800' :
                      task.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-blue-100 text-blue-800'
                    }`}>
                      {task.priority}
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-4 mt-2 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <Clock className="h-4 w-4" />
                    {task.date} {task.time}
                  </div>
                  {subject && (
                    <div className="flex items-center gap-1">
                      <div
                        className="w-3 h-3 rounded-full"
                        style={{ backgroundColor: subject.color }}
                      />
                      <span>{subject.name}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-2">
                <button
                  onClick={() => updateTask(task.id, { 
                    notifications: task.notifications?.length ? [] : [{ id: 1, time: 30, unit: 'minutes' }]
                  })}
                  className={`p-1 rounded hover:bg-gray-100 ${
                    task.notifications?.length ? 'text-indigo-600' : 'text-gray-400'
                  }`}
                >
                  {task.notifications?.length ? (
                    <Bell className="h-4 w-4" />
                  ) : (
                    <BellOff className="h-4 w-4" />
                  )}
                </button>

                <button
                  onClick={() => deleteTask(task.id)}
                  className="p-1 text-gray-400 hover:text-red-500 hover:bg-gray-100 rounded"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}