import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface BreakTimerState {
  isActive: boolean;
  timeLeft: number;
  interval: number;
  lastBreakTime: number | null;
  notifications: boolean;
  setInterval: (minutes: number) => void;
  setNotifications: (enabled: boolean) => void;
  startTimer: () => void;
  pauseTimer: () => void;
  resetTimer: () => void;
  takeBreak: () => void;
}

export const useBreakTimer = create<BreakTimerState>()(
  persist(
    (set, get) => ({
      isActive: false,
      timeLeft: 0,
      interval: 60 * 60 * 1000, // 60 minutes en millisecondes
      lastBreakTime: null,
      notifications: true,

      setInterval: (minutes: number) => {
        set({ interval: minutes * 60 * 1000 });
        get().resetTimer();
      },

      setNotifications: (enabled: boolean) => {
        set({ notifications: enabled });
      },

      startTimer: () => {
        const state = get();
        if (!state.isActive) {
          set({ 
            isActive: true,
            timeLeft: state.lastBreakTime 
              ? Math.max(0, state.interval - (Date.now() - state.lastBreakTime))
              : state.interval
          });

          const timer = setInterval(() => {
            const currentState = get();
            if (currentState.timeLeft <= 0) {
              if (currentState.notifications) {
                new Notification('Temps de pause !', {
                  body: 'Il est temps de faire une pause pour rester productif.',
                  icon: '/favicon.ico'
                });
              }
              clearInterval(timer);
              set({ isActive: false });
            } else {
              set({ timeLeft: currentState.timeLeft - 1000 });
            }
          }, 1000);
        }
      },

      pauseTimer: () => {
        set({ isActive: false });
      },

      resetTimer: () => {
        set({
          isActive: false,
          timeLeft: get().interval,
          lastBreakTime: null
        });
      },

      takeBreak: () => {
        set({
          lastBreakTime: Date.now(),
          isActive: false
        });
      }
    }),
    {
      name: 'break-timer'
    }
  )
);