import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface RevisionSession {
  id: string;
  title: string;
  description?: string;
  subjectId: string;
  date: string;
  duration: number;
  priority: 'high' | 'medium' | 'low';
  objective: string;
  notifyBefore: number;
  completed?: boolean;
}

interface RevisionStore {
  revisionSessions: RevisionSession[];
  addSession: (session: RevisionSession) => void;
  updateSession: (id: string, updates: Partial<RevisionSession>) => void;
  deleteSession: (id: string) => void;
  completeSession: (id: string) => void;
  reset: () => void;
}

export const useRevisionStore = create<RevisionStore>()(
  persist(
    (set) => ({
      revisionSessions: [],
      
      addSession: (session) => set((state) => ({
        revisionSessions: [...state.revisionSessions, session]
      })),
      
      updateSession: (id, updates) => set((state) => ({
        revisionSessions: state.revisionSessions.map(session =>
          session.id === id ? { ...session, ...updates } : session
        )
      })),
      
      deleteSession: (id) => set((state) => ({
        revisionSessions: state.revisionSessions.filter(session => session.id !== id)
      })),
      
      completeSession: (id) => set((state) => ({
        revisionSessions: state.revisionSessions.map(session =>
          session.id === id ? { ...session, completed: true } : session
        )
      })),

      reset: () => set({ revisionSessions: [] })
    }),
    {
      name: 'revision-store'
    }
  )
);