import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GraduationCap, Menu, X, UserCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  let closeTimeout: NodeJS.Timeout;

  const navigation = [
    { name: 'Tâches', path: '/taches' },
    { name: 'Révisions', path: '/revisions' },
    { name: 'Notes', path: '/notes-de-cours' },
    { name: 'Notation', path: '/notation' },
    { name: 'Scanner', path: '/scanner' },
    { name: 'Analyses', path: '/analyses' },
    { name: 'Bien-être', path: '/bien-etre' },
    { name: 'Récompenses', path: '/recompenses' }
  ];

  const handleNavClick = (e: React.MouseEvent) => {
    if (!currentUser) {
      e.preventDefault();
      window.openAuthModal?.();
    }
  };

  const handleMouseEnter = () => {
    if (closeTimeout) clearTimeout(closeTimeout);
    setShowUserMenu(true);
  };

  const handleMouseLeave = () => {
    closeTimeout = setTimeout(() => {
      setShowUserMenu(false);
    }, 150); // Petit délai avant la fermeture
  };

  return (
    <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <Link to="/" className="flex items-center space-x-2">
            <GraduationCap className="h-8 w-8 text-indigo-600" />
            <span className="text-xl font-bold text-gray-900 hidden sm:block">StudyFlow</span>
          </Link>
          
          {/* Navigation desktop */}
          <div className="hidden md:flex items-center space-x-4">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                onClick={handleNavClick}
                className={`px-3 py-2 text-sm font-medium rounded-lg transition-colors ${
                  location.pathname === item.path
                    ? 'text-indigo-600 bg-indigo-50'
                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'
                }`}
              >
                {item.name}
              </Link>
            ))}
          </div>

          {/* User Menu */}
          <div className="flex items-center gap-4">
            {currentUser ? (
              <div 
                className="relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <button className="flex items-center gap-2 text-gray-700 hover:text-indigo-600">
                  <UserCircle className="h-6 w-6" />
                  <span className="text-sm font-medium hidden sm:block">
                    {currentUser.email?.split('@')[0]}
                  </span>
                </button>
                {showUserMenu && (
                  <div 
                    className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50"
                    style={{ marginTop: '0.5rem' }}
                  >
                    <div className="absolute top-0 h-2 w-full -translate-y-2" />
                    <button
                      onClick={() => logout()}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Se déconnecter
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={() => window.openAuthModal?.()}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
              >
                <UserCircle className="h-5 w-5" />
                <span>Connexion</span>
              </button>
            )}
          </div>

          {/* Bouton menu mobile */}
          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-gray-100"
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Menu mobile */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-white border-t"
          >
            <div className="px-4 py-2 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  onClick={(e) => {
                    handleNavClick(e);
                    setIsOpen(false);
                  }}
                  className={`block px-4 py-3 text-base font-medium rounded-lg transition-colors ${
                    location.pathname === item.path
                      ? 'text-indigo-600 bg-indigo-50'
                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50'
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}