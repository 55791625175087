import { useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { syncStore } from '../services/firebaseSync';
import { create } from 'zustand';

// Type générique pour les stores
export interface SyncedStore<T> {
  data: T[];
  setData: (data: T[]) => void;
  add: (item: Omit<T, 'id'>) => void;
  update: (id: string, updates: Partial<T>) => void;
  remove: (id: string) => void;
  reset: () => void;
}

// Hook factory pour créer un store synchronisé
export function createSyncedStore<T extends { id: string }>(storeName: string) {
  return create<SyncedStore<T>>((set, get) => ({
    data: [],
    
    setData: (data) => set({ data }),
    
    add: async (item) => {
      const { currentUser } = useAuth.getState();
      const newItem = { ...item, id: Date.now().toString() } as T;
      
      set((state) => {
        const newData = [...state.data, newItem];
        if (currentUser) {
          syncStore(currentUser.uid, storeName, newData);
        }
        return { data: newData };
      });
    },
    
    update: async (id, updates) => {
      const { currentUser } = useAuth.getState();
      
      set((state) => {
        const newData = state.data.map(item =>
          item.id === id ? { ...item, ...updates } : item
        );
        if (currentUser) {
          syncStore(currentUser.uid, storeName, newData);
        }
        return { data: newData };
      });
    },
    
    remove: async (id) => {
      const { currentUser } = useAuth.getState();
      
      set((state) => {
        const newData = state.data.filter(item => item.id !== id);
        if (currentUser) {
          syncStore(currentUser.uid, storeName, newData);
        }
        return { data: newData };
      });
    },
    
    reset: () => set({ data: [] })
  }));
}

// Hook pour la synchronisation en temps réel
export function useSyncedData(storeName: string, store: any) {
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = onSnapshot(
      doc(db, 'users', currentUser.uid),
      (snapshot) => {
        const data = snapshot.data();
        if (data && data[storeName]) {
          store.setData(data[storeName]);
        }
      },
      (error) => {
        console.error(`Error syncing ${storeName}:`, error);
      }
    );

    return () => unsubscribe();
  }, [currentUser, storeName, store]);
}