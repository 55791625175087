import React from 'react';
import { Brain, Wind, Moon, Activity } from 'lucide-react';

const techniques = [
  {
    id: '1',
    title: 'Respiration 4-7-8',
    description: 'Inspirez pendant 4s, retenez pendant 7s, expirez pendant 8s',
    icon: <Wind className="h-6 w-6" />,
    color: 'bg-blue-500'
  },
  {
    id: '2',
    title: 'Scan corporel',
    description: 'Concentrez-vous sur chaque partie de votre corps successivement',
    icon: <Activity className="h-6 w-6" />,
    color: 'bg-purple-500'
  },
  {
    id: '3',
    title: 'Micro-sieste',
    description: '10-20 minutes de repos pour recharger vos batteries',
    icon: <Moon className="h-6 w-6" />,
    color: 'bg-indigo-500'
  }
];

export default function StressManagement() {
  const [activeId, setActiveId] = React.useState<string | null>(null);

  return (
    <div className="bg-white rounded-2xl p-8 shadow-sm">
      <div className="flex items-center gap-3 mb-6">
        <Brain className="h-6 w-6 text-indigo-600" />
        <h2 className="text-xl font-semibold">Gestion du stress</h2>
      </div>

      <div className="space-y-4">
        {techniques.map((technique) => (
          <div
            key={technique.id}
            className={`p-6 rounded-xl transition-all cursor-pointer ${
              activeId === technique.id
                ? 'bg-gray-50 ring-2 ring-indigo-500'
                : 'hover:bg-gray-50'
            }`}
            onClick={() => setActiveId(activeId === technique.id ? null : technique.id)}
          >
            <div className="flex items-start gap-4">
              <div className={`p-3 rounded-lg ${technique.color} text-white`}>
                {technique.icon}
              </div>
              <div>
                <h3 className="font-medium text-gray-900 mb-1">
                  {technique.title}
                </h3>
                <p className="text-gray-600 text-sm">
                  {technique.description}
                </p>
                
                {activeId === technique.id && (
                  <div className="mt-4 p-4 bg-white rounded-lg border border-gray-200">
                    <h4 className="font-medium text-gray-900 mb-2">
                      Comment pratiquer ?
                    </h4>
                    <ol className="list-decimal list-inside text-sm text-gray-600 space-y-2">
                      <li>Trouvez un endroit calme</li>
                      <li>Installez-vous confortablement</li>
                      <li>Fermez les yeux</li>
                      <li>Suivez les instructions</li>
                    </ol>
                    <button className="mt-4 w-full py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors">
                      Commencer la pratique
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 p-4 bg-indigo-50 rounded-lg">
        <p className="text-sm text-indigo-600">
          💡 Conseil : Pratiquez ces exercices régulièrement, même lorsque vous ne ressentez pas de stress particulier.
        </p>
      </div>
    </div>
  );
}