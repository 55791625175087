import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useGradeStore = create<GradeStore>()(
  persist(
    (set) => ({
      grades: [],
      goals: [],
      classAverages: [],
      
      reset: () => set({ 
        grades: [],
        goals: [],
        classAverages: []
      })
    }),
    {
      name: 'grade-store'
    }
  )
);