import { useTaskStore } from './useTaskStore';
import { useGradeStore } from './useGradeStore';
import { useRevisionStore } from './useRevisionStore';
import { useNotesStore } from './useNotesStore';

export interface BadgeRule {
  id: string;
  title: string;
  description: string;
  points: number;
  icon: React.ReactNode;
  color: string;
  category: 'tasks' | 'grades' | 'revision' | 'notes' | 'streak';
  condition: () => boolean;
}

export const useBadgeRules = () => {
  const { tasks } = useTaskStore();
  const { grades } = useGradeStore();
  const { revisionSessions } = useRevisionStore();
  const { notes } = useNotesStore();

  const rules: BadgeRule[] = [
    // Badges pour les tâches
    {
      id: 'task-starter',
      title: 'Premier pas',
      description: 'Créez votre première tâche',
      points: 50,
      icon: '📝',
      color: '#4F46E5',
      category: 'tasks',
      condition: () => tasks.length > 0
    },
    {
      id: 'task-master',
      title: 'Maître des tâches',
      description: 'Complétez 20 tâches',
      points: 200,
      icon: '✅',
      color: '#10B981',
      category: 'tasks',
      condition: () => tasks.filter(t => t.status === 'done').length >= 20
    },

    // Badges pour les notes
    {
      id: 'grade-achiever',
      title: 'Excellence académique',
      description: 'Obtenez une moyenne supérieure à 15/20',
      points: 300,
      icon: '🎓',
      color: '#F59E0B',
      category: 'grades',
      condition: () => {
        const average = grades.reduce((acc, g) => acc + g.value, 0) / grades.length;
        return average >= 15;
      }
    },
    {
      id: 'perfect-score',
      title: 'Score parfait',
      description: 'Obtenez 20/20 à une évaluation',
      points: 500,
      icon: '🌟',
      color: '#EF4444',
      category: 'grades',
      condition: () => grades.some(g => g.value === 20)
    },

    // Badges pour les révisions
    {
      id: 'revision-starter',
      title: 'Début des révisions',
      description: 'Complétez votre première session de révision',
      points: 100,
      icon: '📚',
      color: '#8B5CF6',
      category: 'revision',
      condition: () => revisionSessions.some(s => s.completed)
    },
    {
      id: 'revision-master',
      title: 'Maître des révisions',
      description: 'Complétez 10 sessions de révision',
      points: 400,
      icon: '🎯',
      color: '#EC4899',
      category: 'revision',
      condition: () => revisionSessions.filter(s => s.completed).length >= 10
    },

    // Badges pour les notes de cours
    {
      id: 'note-taker',
      title: 'Preneur de notes',
      description: 'Créez 5 notes de cours',
      points: 150,
      icon: '📝',
      color: '#6366F1',
      category: 'notes',
      condition: () => notes.length >= 5
    },
    {
      id: 'note-master',
      title: 'Maître des notes',
      description: 'Créez 20 notes de cours',
      points: 300,
      icon: '📔',
      color: '#14B8A6',
      category: 'notes',
      condition: () => notes.length >= 20
    }
  ];

  const checkBadges = async (userId?: string) => {
    const rewardsStore = useRewardsStore.getState();
    
    for (const rule of rules) {
      if (rule.condition() && !rewardsStore.badges.includes(rule.id)) {
        await rewardsStore.unlockBadge(rule.id, userId);
        await rewardsStore.addPoints(rule.points, userId);
      }
    }
  };

  return {
    rules,
    checkBadges
  };
};