import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Flashcard {
  id: string;
  question: string;
  answer: string;
  subjectId: string;
  lastReviewed?: string;
  nextReview?: string;
  success?: boolean;
  reviewCount?: number;
  successCount?: number;
}

interface FlashcardStore {
  flashcards: Flashcard[];
  addFlashcard: (card: Omit<Flashcard, 'id'>) => void;
  updateFlashcard: (id: string, updates: Partial<Flashcard>) => void;
  deleteFlashcard: (id: string) => void;
}

export const useFlashcardStore = create<FlashcardStore>()(
  persist(
    (set) => ({
      flashcards: [],
      
      addFlashcard: (card) => set((state) => ({
        flashcards: [...state.flashcards, {
          ...card,
          id: Date.now().toString(),
          reviewCount: 0,
          successCount: 0
        }]
      })),
      
      updateFlashcard: (id, updates) => set((state) => ({
        flashcards: state.flashcards.map(card =>
          card.id === id ? {
            ...card,
            ...updates,
            reviewCount: (card.reviewCount || 0) + (updates.success !== undefined ? 1 : 0),
            successCount: (card.successCount || 0) + (updates.success ? 1 : 0)
          } : card
        )
      })),
      
      deleteFlashcard: (id) => set((state) => ({
        flashcards: state.flashcards.filter(card => card.id !== id)
      }))
    }),
    {
      name: 'flashcard-store'
    }
  )
);