import React from 'react';
import { useNotesStore } from '../../hooks/useNotesStore';
import { Clock, RotateCcw } from 'lucide-react';

interface VersionHistoryProps {
  noteId: string;
  onClose: () => void;
}

export default function VersionHistory({ noteId, onClose }: VersionHistoryProps) {
  const { notes, restoreVersion } = useNotesStore();
  const note = notes.find(n => n.id === noteId);

  if (!note) return null;

  const handleRestore = (version: number) => {
    restoreVersion(noteId, version);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">Historique des versions</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              ×
            </button>
          </div>

          <div className="space-y-4">
            {note.versions.map((version, index) => (
              <div
                key={version.version}
                className="p-4 border rounded-lg hover:bg-gray-50"
              >
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4 text-gray-500" />
                    <span className="text-sm text-gray-500">
                      Version {version.version} •{' '}
                      {new Date(version.updatedAt).toLocaleString()}
                    </span>
                  </div>
                  {index !== 0 && (
                    <button
                      onClick={() => handleRestore(version.version)}
                      className="flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700"
                    >
                      <RotateCcw className="h-4 w-4" />
                      Restaurer
                    </button>
                  )}
                </div>
                <div
                  className="prose prose-sm"
                  dangerouslySetInnerHTML={{ __html: version.content }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}